import { render, staticRenderFns } from "./EventPage.vue?vue&type=template&id=f3ae3f30&scoped=true&"
import script from "./EventPage.vue?vue&type=script&lang=js&"
export * from "./EventPage.vue?vue&type=script&lang=js&"
import style0 from "./EventPage.vue?vue&type=style&index=0&lang=postcss&"
import style1 from "./EventPage.vue?vue&type=style&index=1&id=f3ae3f30&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3ae3f30",
  null
  
)

export default component.exports