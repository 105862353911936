const getLowestPricedTicketInEvents = (events) => {
    return events.reduce((lowestPricedEvent, event) => {
        return lowestPricedEvent.price.min > event.price.min ? event : lowestPricedEvent
    })
}

const getLowestPricedZoneInZoneGroups = (zoneGroups) => {
    return Object.values(zoneGroups).reduce((lowestPricedZone, zone) => {
        return lowestPricedZone.lowestPricedTicket.all_in_price.price_per > zone.lowestPricedTicket.all_in_price.price_per ? zone : lowestPricedZone
    })
}

const makeZoneGroups = (tickets) => {
    if (!Array.isArray(tickets)) { throw new Error('Invalid Argument: `makeZoneGroups` Array is required.') }

    return tickets.reduce((zoneGroups, ticket) => {
        const zoneGroupId = (((ticket || {}).seating || {}).zone_group || {}).id
        if (!zoneGroupId) { return zoneGroups }

        if (!zoneGroups[zoneGroupId]) {
            zoneGroups[zoneGroupId] = {
                name: ticket.seating.zone_group.name,
                lowestPricedTicket: ticket
            }
        } else if (ticket.all_in_price.price_per < zoneGroups[zoneGroupId].lowestPricedTicket.all_in_price.price_per) {
            zoneGroups[ZoneGroupId].lowestPricedTicket = ticket
        }
        return zoneGroups
    }, {})
}

export {
    getLowestPricedTicketInEvents,
    getLowestPricedZoneInZoneGroups,
    makeZoneGroups
}
