import { render, staticRenderFns } from "./App.vue?vue&type=template&id=10a2c8b3&scoped=true&"
var script = {}
import style0 from "./App.vue?vue&type=style&index=0&lang=postcss&"
import style1 from "./App.vue?vue&type=style&index=1&id=10a2c8b3&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10a2c8b3",
  null
  
)

export default component.exports