<template>
    <transition name="modal">
        <div class="warning-modal-component" :class="{ small: size === 'small', large: size === 'large' }" v-if="open">
            <div class="overlay" aria-label="Close Modal" @click="handleOverlayClose"></div>
            <div class="modal">
                <div class="close-button">
                    <slot name="close-button">
                        <fx-button
                            kind="icon"
                            variant="frameless"
                            size="large"
                            icon="close"
                            aria-label="Close modal"
                            @click="close"
                        />
                    </slot>
                </div>
                <div class="content">
                    <header class="heading">
                        <h2 v-if="heading">{{ heading }}</h2>
                    </header>
                    <slot></slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import ButtonComponent from 'core/components/ButtonComponent'

export default {
    components: {
        'fx-button': ButtonComponent
    },
    props: {
        open: {
            type: Boolean
        },
        heading: {
            type: String
        },
        size: {
            // small|large
            type: String,
            default: 'small'
        }
    },
    created () {
        document.addEventListener('keydown', this.handleKeyDown)
    },
    destroyed () {
        document.removeEventListener('keydown', this.handleKeyDown)
    },
    methods: {
        handleKeyDown (event) {
            if (event.keyCode === 27) {
                this.close()
            }
        },
        handleOverlayClose () {
            this.$emit('close', { method: 'overlay-click' })
        },
        close () {
            this.$emit('close', { method: 'button-click' })
        }
    }
}
</script>

<style lang="postcss" scoped>
@import 'theme';

.warning-modal-component {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: var(--layer-4);

    &.small > .modal {
        width: calc(var(--bp-small) - 32px);
        @media (--mq-small-max) {
            width: calc(100vw - 32px);
        }
    }
    &.large > .modal {
        width: calc(var(--bp-medium) - 32px);
        @media (--mq-medium-max) {
            width: calc(100vw - 32px);
        }
    }
    & > .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--c-overlay);
    }
    & > .modal {
        align-items: stretch;
        background-color: white;
        border-radius: var(--d-border-radius);
        box-shadow: var(--sh-deep);
        display: flex;
        height: 500px;
        overflow: auto;
        position: relative;

        @media (--mq-large-min) {
            height: 700px;
        }

        & > .close-button {
            position: absolute;
            z-index: 1;
            top: 8px;
            right: 8px;
        }
        & > .content {
            flex-grow: 1;
        }
    }
}
</style>
