// Polyfills
require('custom-event-polyfill')

import Vue from 'vue'
import Vuex from 'vuex'
import vuexI18n from 'vuex-i18n'
import VueResource from 'vue-resource'
import VueRouter from 'vue-router'
import VueHead from 'vue-head'
import MobileDetect from 'mobile-detect'
import App from './App.vue'
import MarketplacePlugin from 'core/plugins/MarketplacePlugin.js'
import DatePlugin from 'core/plugins/DatePlugin.js'
import NumeralPlugin from 'core/plugins/NumeralPlugin.js'
import StockTypePlugin from 'core/plugins/StockTypePlugin.js'
import DOMUtil from 'core/utilities/DOMUtil.js'
import GoogleTagManagerPlugin from 'core/external-plugins/GoogleTagManagerPlugin.js'
import FullStoryPlugin from 'core/external-plugins/FullStoryPlugin.js'
import env from  'env'
import 'core-js/stable'
import copy from './copy.json'
import queryString from 'query-string'
import 'regenerator-runtime/runtime'
import routes from './routes.js'

// Initialize plugins
Vue.use(VueHead, {
    separator: '',
    complement: ''
})

Vue.use(DatePlugin)
Vue.use(NumeralPlugin)
Vue.use(StockTypePlugin)

let containerId = env.GOOGLE_TAG_MANAGER_CONTAINER_ID_GROUPON //Groupon GTM Container ID by default
let postMessageTargetOrigin = env.GROUPON_POST_MESSAGE_TARGET_ORIGIN
const query = queryString.parse(location.search)

const whitelabel = query.brand === 'livingsocial' ? 'livingsocial' : 'groupon'

if (whitelabel === 'livingsocial') {
    containerId = env.GOOGLE_TAG_MANAGER_CONTAINER_ID_LIVING_SOCIAL
    postMessageTargetOrigin = env.LIVING_SOCIAL_POST_MESSAGE_TARGET_ORIGIN
}
Vue.use(GoogleTagManagerPlugin, { containerId })

if (env.ENABLE_FULLSTORY) {
    Vue.use(FullStoryPlugin, {
        isIframe: true
    })
}

if (env.ENABLE_OPTIMIZELY) {
    let optimizelyScript = document.createElement('script')

    optimizelyScript.setAttribute('src', 'https://cdn.optimizely.com/js/9265130102.js')
    optimizelyScript.setAttribute('defer', '')

    document.head.appendChild(optimizelyScript)
}

// Initialize HTTP client
Vue.use(VueResource)

const LIVINGSOCIAL_AFFILIATE_ID = '2981'
const GROUPON_AFFILIATE_ID = '2938'

Vue.http.headers.common['X-AFFILIATE-ID'] = whitelabel === 'livingsocial' ? LIVINGSOCIAL_AFFILIATE_ID : GROUPON_AFFILIATE_ID
Vue.http.headers.common['X-CHANNEL'] = 'Web'
Vue.use(MarketplacePlugin, {
    basePath: '/marketplace-api'
})

// Initialize global state
Vue.use(Vuex)
const store = new Vuex.Store({
    strict: true,
    modules: {
        i18n: vuexI18n.store
    },
    state: {
        cdnUrl: env.CDN_URL,
        currency: 'USD',
        isAllInPricing: true,
        isMobile: Boolean(new MobileDetect(window.navigator.userAgent).mobile()),
        selectedTicketPricing: null,
        strikethoughDiscountThreshold: null,
        strikethroughMinPriceThreshold: null,
        whitelabel
    },
    actions: {
        postMessage(_context, { eventType, data }) {
            window.parent.postMessage(JSON.stringify({
                dataVersion: 'v2.0',
                eventType,
                data
            }), postMessageTargetOrigin)
        }
    },
    mutations: {
        setIsAllInPricing(context, isAllInPricing) {
            context.isAllInPricing = isAllInPricing
        },
        setSelectedTicketPricing(context, selectedTicketPricing) {
            context.selectedTicketPricing = selectedTicketPricing
        },
        setStrikethroughMinPriceThreshold(context, strikethroughMinPriceThreshold) {
            context.strikethroughMinPriceThreshold = strikethroughMinPriceThreshold
        },
        setStrikethoughDiscountThreshold(context, strikethoughDiscountThreshold) {
            context.strikethoughDiscountThreshold = strikethoughDiscountThreshold
        }
    }
})

// Initialize i18n plugin
Vue.use(vuexI18n.plugin, store)
// Initialize available languages
for (let language in copy) {
    Vue.i18n.add(language, copy[language])
}
Vue.i18n.set('en')

Vue.http.get('/groupon-api/groupon/configuration')
    .then(response => {
        if (response.body.rules.strikethrough_min_price_threshold) {
            store.commit('setStrikethroughMinPriceThreshold', response.body.rules.strikethrough_min_price_threshold)
        }
        if (response.body.rules.strikethrough_discount_threshold) {
            store.commit('setStrikethoughDiscountThreshold', response.body.rules.strikethrough_discount_threshold)
        }
    })
    .catch(error => {
        console.error(error)
    })

// Initialize application routes
Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            // savedPosition is only available when user navigates with back/forward
            return savedPosition
        } else if (to.name === from.name) {
            // Use current scroll position when url changes but the route is the same
            // example: query param change
            return
        } else {
            // Reset scroll position
            return { x: 0, y: 0 }
        }
    }
})

router.afterEach((to, from) => {
    // Create custom event on route change (for optimizely)
    window.dispatchEvent(new CustomEvent('route-change', {
        detail: {
            to
        }
    }))
})

// Initialize app resize listener
DOMUtil.addElementResizeListener(document.getElementById('appContainer'), element => {
    const isPageFullscreen = store.state.isMobile && router.currentRoute.name === 'event'
    store.dispatch('postMessage', {
        eventType: 'contentResized',
        data: {
            updatedHeight: isPageFullscreen ? (window.screen.availHeight + 37) : (element.offsetHeight + 37),
            fullScreen: isPageFullscreen
        }
    })
})

// Bootstrap application
window.fxApp = new Vue({
    el: '#app',
    render: h => h(App),
    store,
    router
})
