<template>
    <div>
        <warning/>
        <div class="performer-component">
            <header v-if="performer" class="fx-hidden--large fx-hidden--full">
                <h3 class="event-title">{{ performer.name }}</h3>
            </header>
            <div
                class="filters--mobile fx-hidden--large fx-hidden--full">
                <div class="row">
                    <div class="left">
                        <fx-date-range
                            v-model="filters.dateRange"
                            min-date="today"
                            @input="updateEvents(1)"></fx-date-range>
                    </div>
                    <div class="right">
                        <fx-select
                            v-if="filterOptions.locations && filterOptions.locations.length > 1"
                            :options="filterOptions.locations"
                            :default-option="{ label: $t('performer.location-filter-all-option'), value: null }"
                            align="right"
                            v-model="filters.location"
                            @input="updateEvents(1)"></fx-select>
                        <fx-select
                            v-if="filterOptions.opponents && filterOptions.opponents.length > 1"
                            :options="filterOptions.opponents"
                            :default-option="{ label: $t('performer.opponent-filter-all-option'), value: null }"
                            align="right"
                            v-model="filters.opponent"
                            @input="updateEvents(1)"></fx-select>
                    </div>
                </div>
                <fx-tabs
                    class="whereabout-filter"
                    v-if="filterOptions.whereabouts"
                    v-model="filters.whereabouts"
                    :options="filterOptions.whereabouts"
                    @input="updateEvents(1)"></fx-tabs>
            </div>
            <div class="left fx-hidden--small fx-hidden--medium">
                <h3
                    class="event-title"
                    data-e2e="performer-name"
                    v-if="performer">{{ performer.name }}</h3>
                <section v-if="filterOptions.whereabouts">
                    <fx-tabs
                        v-model="filters.whereabouts"
                        :options="filterOptions.whereabouts"
                        @input="updateEvents(1)"></fx-tabs>
                </section>
                <section>
                    <header>
                        <h4 class="heading">{{ $t('performer.date-filter-heading') }}</h4>
                        <fx-button
                            v-show="filters.dateRange"
                            kind="link"
                            @click="resetDateFilter">
                            {{ $t('performer.reset-date-filter-action') }}
                        </fx-button>
                    </header>
                    <div class="date-filter">
                        <fx-date
                            v-model="filters.dateRange"
                            mode="range"
                            min-date="today"
                            @input="updateEvents(1)"></fx-date>
                    </div>
                </section>
                <section v-if="filterOptions.locations && filterOptions.locations.length > 1">
                    <header>
                        <h4 class="heading">{{ $t('performer.location-filter-heading') }}</h4>
                    </header>
                    <fx-select
                        data-e2e="performer.city-filter"
                        :options="filterOptions.locations"
                        :default-option="{ label: $t('performer.location-filter-all-option'), value: null }"
                        v-model="filters.location"
                        @input="updateEvents(1)"></fx-select>
                </section>
                <section v-if="filterOptions.opponents && filterOptions.opponents.length > 1">
                    <header>
                        <h4 class="heading">{{ $t('performer.opponent-filter-heading') }}</h4>
                    </header>
                    <fx-select
                        data-e2e="performer.opponent-filter"
                        :options="filterOptions.opponents"
                        :default-option="{ label: $t('performer.opponent-filter-all-option'), value: null }"
                        v-model="filters.opponent"
                        @input="updateEvents(1)"></fx-select>
                </section>
            </div>
            <div class="right">
                <fx-event-list
                    :events="events"
                    :pagination="pagination"
                    :loading-events="loadingEvents"
                    :loading-more-events="loadingMoreEvents"
                    @event-selected="onEventSelected"
                    @load-more="onLoadMoreEvents">
                    <template slot="event-price-desktop" slot-scope="props">
                        <div class="event-price">
                            <div
                                class="starting-price"
                                v-html="$t('performer.event-start-price', { price: $formatNumeral(props.event.price.min, '$0,0')})"></div>
                            <router-link :to="{ name: 'event', params: { eventId: props.event.id } }">
                                <fx-button
                                    variant="primary"
                                    tabindex="-1">
                                    {{ $t('performer.select-event-action') }}
                                </fx-button>
                            </router-link>
                        </div>
                    </template>
                    <template slot="events-not-found">
                        <div class="event-list-error">
                            <div>
                                <h1 class="error-message">{{ $t('performer.no-events-message') }}</h1>
                                <p class="error-suggestion">{{ $t('performer.no-events-suggestion') }}</p>
                            </div>
                        </div>
                    </template>
                </fx-event-list>
            </div>
        </div>
    </div>
</template>

<script>
import TabsComponent from 'core/components/TabsComponent.vue'
import EventListComponent from 'core/components/EventListComponent.vue'
import DateComponent from 'core/components/DateComponent.vue'
import SelectComponent from 'core/components/SelectComponent.vue'
import ButtonComponent from 'core/components/ButtonComponent.vue'
import DateRangeComponent from 'core/components/DateRangeComponent.vue'
import Utils from 'core/utilities/Utils.js'
import DateUtil from 'core/utilities/DateUtil.js'
import warning from './Warning.vue'

export default {
    head: {
        title() {
            let title = this.$t('event.title-performer-default')
            if (this.performer) {
                title = this.$t('event.title-with-performer', {
                    performer: this.performer.name
                })
                this.$googleTagManager.pushPageView(this.$route.fullPath, title)
            }
            return {
                inner: title
            }
        }
    },
    props: ['performerId'],
    components: {
        'fx-tabs': TabsComponent,
        'fx-event-list': EventListComponent,
        'fx-date': DateComponent,
        'fx-select': SelectComponent,
        'fx-button': ButtonComponent,
        'fx-date-range': DateRangeComponent,
        warning
    },
    data() {
        return {
            performer: null,
            events: [],
            pagination: {},
            aggregations: {},
            filters: {
                whereabouts: null,
                dateRange: null,
                opponent: null,
                location: null
            },
            filterOptions: {
                whereabouts: null,
                opponents: null,
                locations: null
            },
            loadingEvents: false,
            loadingMoreEvents: false,
            isOpponentFilterOpen: false
        }
    },
    created() {
        let query = this.$route.query
        this.filters.opponent = parseInt(query.opponent) || null
        this.filters.location = parseInt(query.location) || null
        if (query.whereabouts) {
            this.filters.whereabouts = query.whereabouts
        }
        if (query.date_from && query.date_to) {
            this.filters.dateRange = [
                DateUtil.parse(query.date_from),
                DateUtil.parse(query.date_to)
            ]
        }

        const getPerformerPromise = this.$marketplace
            .getPerformer(this.performerId)
            .then(performer => {
                this.performer = performer
                this.$emit('updateHead')

                this.$googleTagManager.pushEvent('TrackEventPerformer', {
                    eventCategory: 'Performer',
                    eventAction: 'Pageview',
                    eventLabel: `Performer - ${performer.name}`
                })
            })

        this.$watch('$store.state.isAllInPricing', {
            immediate: true,
            handler() {
                Promise.all([getPerformerPromise, this.updateEvents()])
                    .then(() => {
                        this.$nextTick(() => {
                            this.$store.dispatch('postMessage', {
                                eventType: 'contentLoaded',
                                data: {
                                    finalHeight: document.getElementById('appContainer').offsetHeight
                                }
                            })
                        })
                    })
            }
        })
    },
    methods: {
        updateEvents(page = 1) {
            let dateFrom = null
            let dateTo = null
            if (this.filters.dateRange && this.filters.dateRange.length === 2) {
                const dateFormat = 'YYYY-MM-DD'
                dateFrom = this.$formatDate(this.filters.dateRange[0], dateFormat)
                dateTo = this.$formatDate(this.filters.dateRange[1], dateFormat)
            }
            this.loadingEvents = page === 1
            this.loadingMoreEvents = !this.loadingEvents
            return this.$marketplace.getEntityEvents('performers', this.performerId, {
                whereabouts: this.filters.whereabouts === 'all' ? null : this.filters.whereabouts,
                date_start: dateFrom,
                date_end: dateTo,
                location_id: this.filters.location,
                opponent_id: this.filters.opponent,
                page,
                all_in_price: this.$store.state.isAllInPricing ? 1 : 0,
                utc_offset: this.$formatDate(Date.now(), 'Z')
            })
            .then(this.onUpdateEventsSuccess)
            .finally(response => {
                this.loadingEvents = false
                this.loadingMoreEvents = false
                // Update route
                this.$router.replace({
                    name: this.$route.name,
                    query: Utils.updateQueryParams(this.$route.query, {
                        date_from: dateFrom,
                        date_to: dateTo,
                        opponent: this.filters.opponent,
                        location: this.filters.location,
                        whereabouts: this.filters.whereabouts
                    })
                })
            })
        },
        onUpdateEventsSuccess(response) {
            const events = response.chronological.results
            this.events = this.loadingMoreEvents ? this.events.concat(events) : events
            this.pagination = response.chronological.meta
            this.aggregations = response.chronological.aggs
            let opponentOptions = null
            if (this.aggregations.location) {
                // Update location filter options
                this.filterOptions.locations = this.aggregations.location.map(location => ({
                    value: location.id,
                    label: location.key
                }))
            }
            if (this.aggregations.performers) {
                // Update opponent filter options
                this.filterOptions.opponents = this.aggregations.performers.opponents.map(opponent => ({
                    value: opponent.id,
                    label: opponent.key
                }))
                if (this.aggregations.performers.home_game_count) {
                    // Update whereabout filter options
                    this.filterOptions.whereabouts = [
                        { value: 'home', label: this.$t(`performer.whereabout-options.home`) },
                        { value: 'away', label: this.$t(`performer.whereabout-options.away`) },
                        { value: 'all', label: this.$t(`performer.whereabout-options.all`) }
                    ]
                }
            }
        },
        resetDateFilter() {
            this.filters.dateRange = null
            this.updateEvents()
        },
        onEventSelected(event) {
            this.$router.push({
                name: 'event',
                params: {
                    eventId: event.id
                }
            })
        },
        onLoadMoreEvents() {
            if (this.pagination.current_page < this.pagination.last_page) {
                this.updateEvents(this.pagination.current_page + 1)
            }
        }
    }
}
</script>

<style lang="postcss">
@import "theme";

.filters--mobile > .whereabout-filter {
    border-radius: 0 !important;
    border: none !important;
    & > li {
        border-right: none !important;
        border-bottom: solid 2px transparent;
        &.active {
            background: white !important;
            border-bottom-color: var(--c-accent);
            color: var(--c-accent) !important;
        }
    }
}

.fx-event-list-wrapper {
    @media (--mq-medium-max) {
        & > .fx-list {
            border-top: none !important;
            border-left: none !important;
            border-right: none !important;
        }
    }
}

.event-price > .starting-price > .price {
    font-size: var(--fs-large);
    font-weight: var(--fw-medium);
    color: var(--c-gray-1);
}

</style>

<style lang="postcss" scoped>
@import "theme";

.performer-component {
    display: flex;
    @media (--mq-medium-max) {
        flex-direction: column;
    }
    & > header {
        padding: 12px;
        & > .event-title {
            margin: 0;
        }
    }
    & > footer {
        padding: 12px;
    }
    & > .left {
        width: var(--d-column);
        flex-basis: var(--d-column);
        flex-shrink: 0;
        flex-grow: 0;
        padding-right: 12px;
        & > section {
            padding: 24px 0;
            border-bottom: solid 1px var(--c-gray-8);
            &:first-of-type {
                padding-top: 0;
            }
            &:last-of-type {
                padding-bottom: 0;
                border-bottom: none;
            }
            & > header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 16px;
                & > .heading {
                    font-weight: var(--fw-normal);
                    margin: 0;
                }
            }
        }
    }
    & > .right {
        flex-basis: 100%;
        flex-grow: 1;
        padding-left: 12px;
        @media (--mq-medium-max) {
            flex-basis: auto;
            flex-grow: 0;
            padding-left: 0;
        }
    }
}

.event-title {
    margin: 0;
    margin-bottom: 16px;
    font-size: var(--fs-larger);
    font-weight: var(--fw-medium);
    & > .event-count {
        font-weight: var(--fw-normal);
        font-size: var(--fs-normal);
    }
}

.event-list {
    border: solid 1px var(--c-gray-3);
    & > li {
        border-bottom: solid 1px var(--c-gray-3);
        padding: 16px;
        &:last-child {
            border-bottom: none;
        }
    }
}

.date-filter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.event-list-error {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    color: var(--c-gray-4);
    text-align: center;
    & .error-message {
        font-weight: var(--fw-normal);
        font-size: 28px;
    }
    & .error-suggestion {
        font-size: var(--fs-normal);
    }
}

.event-price {
    & > .starting-price {
        margin-bottom: 8px;
        text-align: center;
        color: var(--c-gray-4);
        font-weight: var(--fw-normal);
        & > .price {
            font-size: var(--fs-large);
            font-weight: var(--fw-medium);
            color: var(--c-gray-1);
        }
    }
}

.filters--mobile {
    border-bottom: solid 1px var(--c-gray-8);
    & > .row {
        padding: 6px 12px;
        display: flex;
        justify-content: space-between;
        &:nth-child(1) {
            & > .left, & > .right {
                max-width: 50%;
            }
        }
    }
}

>>> .flatpickr-day {
    &:hover {
        background: var(--color-prim-lt) !important;
        background-color: var(--color-prim-lt) !important;
        border-color: var(--color-prim-lt) !important;
        &.disabled {
            background: none !important;
            background-color: none !important;
            border-color: transparent !important;
        }
        &.startRange, &.endRange {
            background: var(--color-prim) !important;
            background-color: var(--color-prim) !important;
            border-color: var(--color-prim) !important;
        }
        &.today {
            background: var(--color-prim-lt) !important;
            background-color: var(--color-prim-lt) !important;
        }
        &.nextMonthDay {
            background: var(--color-gray-lt) !important;
            background-color: var(--color-gray-lt) !important;
            border-color: var(--color-gray-lt) !important;
            &.inRange {
                background-color: var(--color-prim-lt) !important;
                border-color: var(--color-prim-lt) !important;
                box-shadow: -5px 0 0 var(--color-prim-lt), 5px 0 0 var(--color-prim-lt) !important;
            }
            &.startRange, &.endRange {
                background: var(--color-prim) !important;
                background-color: var(--color-prim) !important;
                border-color: var(--color-prim) !important;
                box-shadow: none !important;
            }
        }
    }
    &.inRange {
        background-color: var(--color-prim-lt) !important;
        border-color: var(--color-prim-lt) !important;
        box-shadow: -5px 0 0 var(--color-prim-lt), 5px 0 0 var(--color-prim-lt) !important;
    }
    &.startRange, &.endRange {
        background-color: var(--color-prim) !important;
        border-color: var(--color-prim) !important;
        box-shadow: none !important;
        &::after, &::before {
            background-color: var(--color-prim-lt) !important
        }
    }
}

>>> .fx-tabs li {
    &.active {
        background: var(--color-prim) !important;
    }
    &:hover {
        background: var(--color-prim-lt) !important;
    }
}

>>> .filters--mobile > .whereabout-filter > li.active {
    background: #ffffff !important;
    border-bottom-color: var(--color-prim) !important;
    color: var(--color-prim) !important;
}

>>> .fx-button.kind--default.variant--primary {
    background: var(--color-prim) !important;
    &:hover {
        background: var(--color-prim-dk) !important;
    }
}

>>> .fx-select-dropdown > ul > li {
    &.selected {
        color: var(--color-prim) !important;
    }
    &:focus, &:hover {
        background-color: var(--color-prim-lt) !important;
    }
}
>>> .kind--link {
    color: var(--color-prim) !important;
    &:active {
        color: var(--color-prim-lt) !important;
        border-color: var(--color-prim-lt) !important;
    }
    &:hover, &:focus {
        border-color: var(--color-prim) !important;
    }
}

>>> .load-more-button {
    color: var(--color-prim) !important;
    &:focus, &:hover {
        background-color: var(--color-prim-lt) !important;
    }
}

>>>.spinner > .path {
    stroke: var(--color-prim) !important;
}

h4 {
    font-size: initial !important;
}

>>> .fx-event-title, >>> .price, >>> .flatpickr-current-month > .cur-month, >>> .fx-selection, >>> .event-title {
    font-weight: 600 !important;
}

>>> .filters--mobile li.active {
    border-bottom-color: var(--color-prim) !important;
    color: var(--color-prim) !important;
}

>>> .event-price--mobile {
    color: var(--color-prim) !important;
}

>>> .flatpickr-weekday {
    font-weight: 700 !important;
}

>>> .mobile .fx-continue {
    color: var(--color-prim) !important;
}
</style>
