import Vue from 'vue'
import CovidImpactPage from './views/pages/CovidImpactPage.vue'
import EventPage from './views/pages/EventPage.vue'
import GuaranteePage from './views/pages/GuaranteePage.vue'
import NotFoundPage from './views/pages/NotFoundPage.vue'
import PerformerPage from './views/pages/PerformerPage.vue'
import PrivacyPage from './views/pages/PrivacyPage.vue'
import TermsAndConditionsPage from './views/pages/TermsAndConditionsPage.vue'
import ZonedPerformerPage from './views/pages/ZonedPerformerPage.vue'

export default [
    {
        name: 'performer',
        path: '/performers/:performerId',
        component: PerformerPage,
        props: true
    },
    {
        name: 'event',
        path: '/events/:eventId',
        component: EventPage,
        props: true
    },
    {
        name: 'not-found',
        path: '/not-found',
        component: NotFoundPage
    },
    {
        name: 'terms',
        path: '/terms',
        component: TermsAndConditionsPage
    },
    {
        name: 'zoned-performer',
        path: '/zoned-performers/:performerId',
        component: ZonedPerformerPage,
        props: true
    },
    {
        name: 'covid',
        path: '/covid',
        component: CovidImpactPage
    },
    {
        name: 'guarantee',
        path: '/guarantee',
        component: GuaranteePage
    },
    {
        name: 'privacy',
        path: '/privacy',
        component: PrivacyPage
    },
    {
        path: '*',
        component: NotFoundPage
    }
]
