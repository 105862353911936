<template>
    <div class="page">
        <h1>Information Regarding the Coronavirus Impact</h1>
        <p>Vivid Seats is closely monitoring the current global situation involving the coronavirus (COVID-19), especially as it relates to the impact on live events. We want to make sure customers have the information they need regarding their Vivid Seats tickets purchase on {{partnerName}}.</p>
        <p>If a customer purchased tickets to an event that is <strong>canceled</strong> with no rescheduled date, they are entitled to a full refund of the purchase price, including delivery charges, per Vivid Seats’ 100% Buyer Guarantee. There is no need to take any action, and our award-winning customer service team will contact customers directly regarding next steps.</p>
        <p>If a customer's event has been <strong>postponed</strong>, the purchase will likely be valid for the new date, though every event may be different. There is no need to take any action, and our customer service team will contact customers directly once we have an update from event organizers. Vivid Seats has an outstanding track record of providing excellent customer service, and we are committed to helping our customers during this time.</p>
        <p>As always, we encourage customers to reach out to us if they have any questions, as we are dedicated to making sure every customer is satisfied.</p>
        <h1>Buyer FAQs</h1>
        <section>
            <h3>I purchased tickets to an event that has been canceled due to the coronavirus (COVID-19). What happens now?</h3>
            <ul>
                <li>If your event has been canceled, your purchase is entitled to a full refund of the purchase price, including delivery charges, per our 100% Buyer Guarantee. There is no action you need to take, and our customer service team will email you with details on a full refund.</li>
            </ul>
        </section>
        <section>
            <h3>When will I receive my full refund?</h3>
            <ul>
                <li>If your event was canceled and you opted for a full refund, you should expect to receive your refund within approximately 2-4 weeks.</li>
                <li>Refund timing may vary due to the large number of events being canceled.</li>
            </ul>
        </section>
        <section>
            <h3>How do I find out whether or not my show is canceled or postponed due to the coronavirus (COVID-19)?</h3>
            <ul>
                <li>Events may be canceled by the team, venue, performer, or promoter. Ticket marketplaces like Vivid Seats do not control these decisions. Therefore, we recommend checking the venue and artist websites, event pages, and social media channels. Vivid Seats will email customers whose events are canceled or postponed.</li>
            </ul>
        </section>
        <section>
            <h3>Do you still have questions?</h3>
            <ul>
                <li>Email our customer service team at <a class="email" :href="`mailto:${ partnerEmail }`">{{partnerEmail}}</a>, and we'll get back to you!</li>
            </ul>
        </section>
        <section>
          <h3>Event Attendance Information: Covid-19 Disclaimer</h3>
          <p>Due to the uncertainty related to the COVID-19 pandemic, your tickets and admission to the event are subject to all venue and/or event organizer/promoter safety and health policies. The venue and/or event organizer/promoter may continue to develop and update these policies in the intervening time between your purchase and the event date. It is your responsibility to monitor and comply with such policies and your attendance at the event is conditioned on such compliance. If your admission to the event is denied or revoked because you have willfully failed or refused to comply with any such safety and health policies of the venue and/or event organizer/promoter, you will not be eligible for any refund or other compensation.</p>
        </section>
    </div>
</template>
<script>
export default {
    computed: {
        partnerName () {
            return this.$store.state.whitelabel === 'livingsocial' ? 'Living Social': 'Groupon'
        },
        partnerEmail () {
            const serviceName = this.$store.state.whitelabel === 'livingsocial' ? 'LivingSocial': 'groupon';
            return `${serviceName}@vividseats.com`
        }
    }
}
</script>

<style lang="postcss">

.page {
    padding: 0 10px 0 10px;
}

.email {
    color: var(--color-prim);
}
</style>
