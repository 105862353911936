<template>
    <div>
        <warning/>
        <header
            class="zone-group-header"
            v-if="performer"
        >
            <h1 class="zone-group-header__performer">{{ performer.name }}</h1>
            <div class="zone-group-header__venue-container">
                <span class="icon-location-marker"></span>
                <h4 class="zone-group-header__venue">{{ performer.home_venue.name }}</h4>
            </div>
        </header>

        <div class="zone-group">
            <div class="map-container">
                <div
                    class="map-status"
                    v-if="isFetchingEvents"
                >
                    <CoreSpinner/>
                </div>
                <img
                    class="map"
                    v-else-if="performer"
                    :alt="`${performer.home_venue.name} venue map`"
                    :src="performer.home_venue.map.static_zone_source"
                >
                <img
                    alt="No Map Found"
                    class="map-status"
                    src="https://d2d6ul3tefw3xz.cloudfront.net/production/marketplace-core/images/missing-venue-map.svg"
                    v-else
                >
            </div>

            <div class="panel-container">
                <div class="lowest-available-price">
                    <div class="lowest-available-price__value">
                        <div v-if="isFetchingLowestPriceAvailable">Loading...</div>
                        <div
                            class="strikethrough-pricing"
                            v-else-if="lowestTicketPriceAvailable.strikeThroughPriceDiscount"
                        >
                            <div>
                                <div
                                    class="strikethrough-pricing__from"
                                    :class="{ 'strikethrough-pricing__from--hidden': isRequiredSectionsFilled }"
                                >
                                    From
                                </div>
                                <span class="strikethrough-pricing__original-price">
                                    {{ $formatNumeral(lowestTicketPriceAvailable.pastLowestTicketPrice, '$0,0.00') }}
                                </span>
                                <span>
                                    <span class="strikethrough-pricing__new-price">{{ $formatNumeral(lowestTicketPriceAvailable.lowestTicketPrice, '$0,0.00') }}</span>
                                    <span>/ea.</span>
                                </span>
                            </div>
                            <div class="strikethrough-pricing__discount-message">
                                <div>
                                    <strong>{{ $formatNumeral(lowestTicketPriceAvailable.strikeThroughPriceDiscount, '0%') }} LESS</strong>
                                </div>
                                <div>
                                    than {{ lowestTicketPriceAvailable.span }} days ago
                                </div>
                            </div>
                        </div >
                        <div v-else-if="lowestTicketPriceAvailable">
                            <div
                                class="lowest-available-price__from"
                                v-show="!isRequiredSectionsFilled"
                            >
                                From
                            </div>
                            <span>
                                <span class="lowest-available-price__price">

                                    {{ $formatNumeral(lowestTicketPriceAvailable.lowestTicketPrice, '$0,0.00') }}
                                </span>
                                <span>/ea.</span>
                            </span>
                        </div>
                        <div v-else>No Minimum Price Available</div>
                    </div>
                </div>

                <div class="panel">
                    <section
                        class="panel-step"
                        :class="{
                            'panel-step--open': isSelectAnEventStepOpen,
                            'panel-step--disabled': !isValidStep(STEP.SELECT_AN_EVENT),
                            'panel-step--clickable': isValidStep(STEP.SELECT_AN_EVENT)
                        }"
                    >
                        <header class="panel-step__header">
                            <span
                                class="section-header-container"
                                @click="isSelectAnEventStepOpen ? closeOpenStep() : setStep(STEP.SELECT_AN_EVENT)"
                            >
                                <span class="icon-calendar-blank"></span>
                                <div
                                    class="section-header-text"
                                    v-if="selectedEvent && !isSelectAnEventStepOpen"
                                >
                                    <div class="date">{{ selectedEvent.local_date | date('ddd, MMM D &bull; h:mma') }}</div>
                                    <div>{{ selectedEvent.name }}</div>
                                </div>
                                <h2
                                    class="section-header-text"
                                    v-else
                                >
                                    Select a Game
                                </h2>
                            </span>
                            <div>
                                <button
                                    class="collapse-arrow-down"
                                    v-if="!isSelectAnEventStepOpen"
                                    @click="setStep(STEP.SELECT_AN_EVENT)"
                                >
                                    <span class="icon-chevron-lg-down"></span>
                                </button>
                                <button
                                    class="collapse-arrow-up"
                                    v-else
                                    @click="closeOpenStep()"
                                >
                                    <span class="icon-chevron-lg-up"></span>
                                </button>
                            </div>
                        </header>
                        <transition name="accordion">
                            <div
                                class="panel-step__content"
                                v-show="isSelectAnEventStepOpen"
                            >
                                <CoreSpinner v-if="isFetchingEvents"/>
                                <ul
                                    class="panel-step__events"
                                    v-else-if="events.length"
                                >
                                    <li
                                        class="panel-step__event"
                                        v-for="event in events"
                                        :class="{ 'panel-step__event--selected': isEventSelected(event) }"
                                        :key="event.id"
                                        @click="selectEvent(event)"
                                    >
                                        <div class="date">
                                            {{ event.local_date | date('ddd, MMM D &bull; h:mma') }}</div>
                                        <div>{{ event.name }}</div>
                                    </li>
                                </ul>
                                <div v-else>No Events Found.</div>
                            </div>
                        </transition>
                    </section>

                    <section
                        class="panel-step"
                        :class="{
                            'panel-step--open': isSelectAQuantityStepOpen,
                            'panel-step--disabled': !isValidStep(STEP.SELECT_A_QUANTITY),
                            'panel-step--clickable': isValidStep(STEP.SELECT_A_QUANTITY)
                        }"
                    >
                        <header class="panel-step__header">
                            <span
                                class="section-header-container"
                                @click="isSelectAQuantityStepOpen ? closeOpenStep() : setStep(STEP.SELECT_A_QUANTITY)"
                            >
                                <span
                                    class="icon-ticket-2 icon"
                                    :class="{ 'icon--disabled': !isValidStep(STEP.SELECT_A_QUANTITY) }"
                                >
                                </span>
                                <div
                                    class="section-header-text"
                                    v-if="selectedQuantity && !isSelectAQuantityStepOpen"
                                >
                                    {{ pluralizeQuantity(selectedQuantity) }}
                                </div>
                                <h2
                                    class="section-header-text"
                                    :class="{ 'section-header-text--disabled': !isValidStep(STEP.SELECT_A_QUANTITY) }"
                                    v-else
                                >
                                    Number of Tickets
                                </h2>
                            </span>
                            <div>
                                <button
                                    class="collapse-arrow-down"
                                    v-if="!isSelectAQuantityStepOpen"
                                    @click="setStep(STEP.SELECT_A_QUANTITY)"
                                >
                                    <span
                                        class="icon-chevron-lg-down"
                                        :class="{ 'icon-chevron-lg-down--disabled': !isValidStep(STEP.SELECT_A_QUANTITY) }"
                                    >
                                    </span>
                                </button>
                                <button
                                    class="collapse-arrow-up"
                                    v-else
                                    @click="closeOpenStep()"
                                >
                                    <span class="icon-chevron-lg-up"></span>
                                </button>
                            </div>
                        </header>
                        <transition name="accordion">
                            <div
                                class="panel-step__content"
                                v-show="isSelectAQuantityStepOpen"
                            >
                                <CoreSpinner v-if="isFetchingTickets"/>
                                <div
                                    class="available-quantities"
                                    v-else-if="availableQuantities.length"
                                >
                                    <button
                                        class="available-quantities__less"
                                        :class="{ 'available-quantities__less--hidden': quantityButtonPage === 0 }"
                                        @click="quantityButtonPage = quantityButtonPage - 1"
                                    >
                                        <span class="icon-chevron-lg-left"></span>
                                    </button>
                                    <ul class="available-quantities__buttons">
                                        <li
                                            class="available-quantities__button-container"
                                            v-for="quantity in availableQuantitiesPaginated[quantityButtonPage]"
                                            :key="quantity"
                                        >
                                            <button
                                                class="available-quantities__button"
                                                :class="{ 'available-quantities__button--selected': quantity === selectedQuantity}"
                                                @click="selectQuantity(quantity)"
                                            >
                                                {{ quantity }}
                                            </button>
                                        </li>
                                    </ul>
                                    <button
                                        class="available-quantities__more"
                                        :class="{ 'available-quantities__more--hidden': quantityButtonPage === availableQuantitiesPaginated.length - 1 }"
                                        @click="quantityButtonPage = quantityButtonPage + 1"
                                    >
                                        <span class="icon-chevron-lg-right"></span>
                                    </button>
                                </div>
                                <div v-else>No Tickets Found</div>
                            </div>
                        </transition>
                    </section>

                    <section
                        class="panel-step"
                        :class="{
                            'panel-step--open': isSelectAZoneStepOpen,
                            'panel-step--disabled': !isValidStep(STEP.SELECT_A_ZONE),
                            'panel-step--clickable': isValidStep(STEP.SELECT_A_ZONE)
                        }"
                    >
                        <header class="panel-step__header">
                            <span
                                class="section-header-container"
                                @click="isSelectAZoneStepOpen ? closeOpenStep() : setStep(STEP.SELECT_A_ZONE)"
                            >
                                <span
                                    class="icon-stadium icon"
                                    :class="{ 'icon--disabled': !isValidStep(STEP.SELECT_A_ZONE) }"
                                >
                                </span>
                                <div
                                    class="section-header-text"
                                    v-if="selectedTicket && !isSelectAZoneStepOpen"
                                >
                                    {{ this.selectedTicket.seating.zone_group.name }}
                                </div>
                                <h2
                                    class="section-header-text"
                                    v-else
                                    :class="{ 'section-header-text--disabled': !isValidStep(STEP.SELECT_A_ZONE) }"
                                >
                                    Select Seating
                                </h2>
                            </span>
                            <div>
                                <button
                                    class="collapse-arrow-down"
                                    v-if="!isSelectAZoneStepOpen"
                                    @click="setStep(STEP.SELECT_A_ZONE)"
                                >
                                    <span
                                        class="icon-chevron-lg-down"
                                        :class="{ 'icon-chevron-lg-down--disabled': !isValidStep(STEP.SELECT_A_ZONE) }"

                                    >
                                    </span>
                                </button>
                                <button
                                    class="collapse-arrow-up"
                                    v-else
                                    @click="closeOpenStep()"
                                >
                                    <span class="icon-chevron-lg-up"></span>
                                </button>
                            </div>
                        </header>
                        <transition name="accordion">
                            <div
                                class="panel-step__content"
                                v-show="isSelectAZoneStepOpen"
                            >
                                <CoreSpinner v-if="isFetchingTicketsWithMinimumQuantity"/>
                                <ul
                                    class="panel-step__zones"
                                    v-else-if="Object.keys(zoneGroups).length"
                                >
                                    <li
                                        class="panel-step__zone-grouping"
                                        v-for="(zoneGroup, zoneGroupId) in zoneGroups"
                                        :class="{ 'panel-step__zone-grouping--selected': isZoneGroupSelected(zoneGroupId) }"
                                        :key="zoneGroupId"
                                        @click="selectZoneGroup(zoneGroup)"
                                    >
                                        <div class="left">
                                            <div>{{ zoneGroup.name }}</div>
                                            <div
                                                class="zone-grouping__strikethrough-message"
                                                v-if="isTicketStrikethroughPricing(zoneGroup.lowestPricedTicket, $store.state.strikethroughMinPriceThreshold, $store.state.strikethoughDiscountThreshold)"
                                            >
                                                <strong>{{ $formatNumeral(calculatePercentageDifference(zoneGroup.lowestPricedTicket.all_in_price.price_per, zoneGroup.lowestPricedTicket.price_trend.past_min_price), '0%') }} LESS</strong>
                                                than {{ zoneGroup.lowestPricedTicket.price_trend.span }} days ago
                                            </div>
                                        </div>
                                        <div
                                            class="zone-grouping__prices">
                                            <span
                                                class="zone-grouping__original_price"
                                                v-if="isTicketStrikethroughPricing(zoneGroup.lowestPricedTicket, $store.state.strikethroughMinPriceThreshold, $store.state.strikethoughDiscountThreshold)"
                                            >
                                                {{ $formatNumeral(zoneGroup.lowestPricedTicket.price_trend.past_min_price, '$0,0.00') }}
                                            </span>
                                            <div
                                                class="zone-grouping__current_price"
                                                :class="{'zone-grouping__current_price--strikethrough': isTicketStrikethroughPricing(zoneGroup.lowestPricedTicket, $store.state.strikethroughMinPriceThreshold, $store.state.strikethoughDiscountThreshold)}"
                                            >
                                                <div>{{ $formatNumeral(zoneGroup.lowestPricedTicket.all_in_price.price_per, '$0,0.00') }}&nbsp;/ea.</div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div v-else>No Tickets Found</div>
                            </div>
                        </transition>
                    </section>
                    <section>
                        <button
                            class="clear-all-sections"
                            :class="{ 'clear-all-sections--disabled': !selectedEvent }"
                            :disabled="!selectedEvent"
                            @click="clearAllSections"
                        >
                            Clear All Selections
                        </button>
                    </section>

                </div>

                <div class="buy-button-container">
                    <button
                        class="buy-button"
                        :class="{ 'buy-button--disabled': !isRequiredSectionsFilled }"
                        :disabled="!isRequiredSectionsFilled"
                        @click="purchase()"
                    >
                        Buy
                    </button>
                </div>

                <div class="price-breakdown" v-if="this.selectedTicket && this.selectedTicket.price_breakdown">
                    <ul class="breakdown">
                        <li><span class="text-left">Tickets</span><span class="text-right">{{ getStringWithQuantity($formatNumeral(this.selectedTicket.price_breakdown.price, '$0,0.00'),selectedQuantity) }}</span></li>
                        <li><span
                            class="text-left">Service Fee</span><span class="text-right">{{ getStringWithQuantity($formatNumeral(calculateFeeBreakdown(this.selectedTicket.price_breakdown, event.venue.subdivision), '$0,0.00'),selectedQuantity) }}</span></li>
                        <li><span class="text-left">Delivery Fee</span><span v-if="this.selectedTicket.price_breakdown.delivery_fee > 0" class="text-right">{{
                            getStringWithQuantity($formatNumeral(this.selectedTicket.price_breakdown.delivery_fee, '$0,0.00'),selectedQuantity)
                        }}</span><span v-if="this.selectedTicket.price_breakdown.delivery_fee === 0" class="text-right free">FREE</span>
                        </li>
                        <li class="total-row"><span class="text-left">TOTAL</span><span class="text-right total-price">{{getStringWithQuantity($formatNumeral(this.selectedTicket.price_breakdown.total, '$0,0.00'),selectedQuantity)}}</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CoreSpinner from 'core/components/SpinnerComponent.vue'
import env from 'env'
import { calculateFeeBreakdown } from '../../services/TicketService'
import {
    getLowestPricedTicketInEvents,
    getLowestPricedZoneInZoneGroups,
    makeZoneGroups
} from '../../services/zoneGroupService'
import {
    isTicketStrikethroughPricing,
    isEventStrikethroughPricing,
    calculatePercentageDifference,
    getStringWithQuantity
} from '../../services/StrikethroughService'
import warning from './Warning.vue'

const STEP = {
    SELECT_AN_EVENT: 1,
    SELECT_A_QUANTITY: 2,
    SELECT_A_ZONE: 3,
    FINALIZE_DETAILS: 4
}

export default {
    head: {
        title() {
            let title = this.$t('event.title-performer-default')
            if (this.performer) {
                title = this.$t('event.title-with-performer', {
                    performer: this.performer.name
                })
                this.$googleTagManager.pushPageView(this.$route.fullPath, title)
            }
            return {
                inner: title
            }
        }
    },
    components: {
        CoreSpinner,
        warning
    },
    computed: {
        availableQuantitiesPaginated() {
            return this.availableQuantities.reduce((paginatedButtons, quantity, index) => {
                const pageSize = 7
                const page = Math.floor(index/pageSize)

                if (!paginatedButtons[page]) {
                    paginatedButtons[page] = []
                }

                paginatedButtons[page].push(quantity)

                return paginatedButtons
            }, [])
        },
        isFetchingLowestPriceAvailable() {
            return this.isFetchingEvents || this.isFetchingTickets || this.isFetchingTicketsWithMinimumQuantity
        },
        isSelectAnEventStepOpen() {
            return this.openStep === STEP.SELECT_AN_EVENT
        },
        isSelectAQuantityStepOpen() {
            return this.openStep === STEP.SELECT_A_QUANTITY
        },
        isSelectAZoneStepOpen() {
            return this.openStep === STEP.SELECT_A_ZONE
        },
        isRequiredSectionsFilled() {
            return Boolean(this.selectEvent && this.selectedQuantity && this.selectedTicket && !this.isFetchingLowestPriceAvailable)
        },
        lowestTicketPriceAvailable() {
            if (this.selectedTicket) {
                return {
                    lowestTicketPrice: this.selectedTicket.all_in_price.price_per,
                    pastLowestTicketPrice: this.selectedTicket.price_trend ? this.selectedTicket.price_trend.past_min_price : null,
                    span: this.selectedTicket.price_trend ? this.selectedTicket.price_trend.span : null,
                    strikeThroughPriceDiscount: isTicketStrikethroughPricing(this.selectedTicket, this.$store.state.strikethroughMinPriceThreshold, this.$store.state.strikethoughDiscountThreshold) ?
                        calculatePercentageDifference(this.selectedTicket.all_in_price.price_per, this.selectedTicket.price_trend.past_min_price) :
                        null
                }
            }
            if (Object.keys(this.zoneGroups).length) {
                const lowestPriceTicketInZone = getLowestPricedZoneInZoneGroups(this.zoneGroups).lowestPricedTicket
                return {
                    lowestTicketPrice: lowestPriceTicketInZone.all_in_price.price_per,
                    pastLowestTicketPrice: lowestPriceTicketInZone.price_trend ? lowestPriceTicketInZone.price_trend.past_min_price : null,
                    span: lowestPriceTicketInZone.price_trend ? lowestPriceTicketInZone.price_trend.span : null,
                    strikeThroughPriceDiscount: isTicketStrikethroughPricing(lowestPriceTicketInZone, this.$store.state.strikethroughMinPriceThreshold, this.$store.state.strikethoughDiscountThreshold) ?
                        calculatePercentageDifference(lowestPriceTicketInZone.all_in_price.price_per, lowestPriceTicketInZone.price_trend.past_min_price) :
                        null
                }
            }
            if (this.selectedEvent) {
                return {
                    lowestTicketPrice: this.selectedEvent.price.min,
                    pastLowestTicketPrice: this.selectedEvent.price_trend ? this.selectedEvent.price_trend.past_min_price : null,
                    span: this.selectedEvent.price_trend ? this.selectedEvent.price_trend.span : null,
                    strikeThroughPriceDiscount: isEventStrikethroughPricing(this.selectedEvent, this.$store.state.strikethroughMinPriceThreshold, this.$store.state.strikethoughDiscountThreshold) ?
                        calculatePercentageDifference(this.selectedEvent.price.min, this.selectedEvent.price_trend.past_min_price) :
                        null
                }
            }
            if (this.events.length) {
                const lowestPricedTicketInEvents = getLowestPricedTicketInEvents(this.events)
                return {
                    lowestTicketPrice: lowestPricedTicketInEvents.price.min,
                    pastLowestTicketPrice: lowestPricedTicketInEvents.price_trend ? lowestPricedTicketInEvents.price_trend.past_min_price : null,
                    span: lowestPricedTicketInEvents.price_trend ? lowestPricedTicketInEvents.price_trend.span : null,
                    strikeThroughPriceDiscount: isEventStrikethroughPricing(lowestPricedTicketInEvents, this.$store.state.strikethroughMinPriceThreshold, this.$store.state.strikethoughDiscountThreshold) ?
                        calculatePercentageDifference(lowestPricedTicketInEvents.price.min, lowestPricedTicketInEvents.price_trend.past_min_price) :
                        null
                }
            }
            return 0
        }
    },
    created() {
        this.STEP = STEP
    },
    data() {
        return {
            availableQuantities: [],
            events: [],
            isFetchingEvents: false,
            isFetchingTickets: false,
            isFetchingTicketsWithMinimumQuantity: false,
            openStep: STEP.SELECT_AN_EVENT,
            performer: null,
            quantityButtonPage: 0,
            selectedEvent: null,
            selectedQuantity: null,
            selectedTicket: null,
            step: STEP.SELECT_AN_EVENT,
            zoneGroups: {}
        }
    },
    methods: {
        calculateFeeBreakdown,
        calculatePercentageDifference,
        getStringWithQuantity,
        isTicketStrikethroughPricing,
        fetchTicketsForSelectedEvent() {
            this.isFetchingTickets = true
            this.$marketplace
                .getTickets(this.selectedEvent.id, { all_in_price: 1 })
                .then(response => {
                    this.availableQuantities = response.meta.quantities

                    if (this.isPreviousSelectedQuantityStillValid(this.selectedQuantity)) {
                        this.fetchTicketsWithMinimumQuantity()
                    } else {
                        this.quantityButtonPage = 0
                        this.selectedQuantity = null
                        this.selectedTicket = null
                    }
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    this.isFetchingTickets = false
                })
        },
        fetchTicketsWithMinimumQuantity() {
            this.isFetchingTicketsWithMinimumQuantity = true
            this.$marketplace
                .getTickets(this.selectedEvent.id, {
                    all_in_price: 1,
                    quantity: this.selectedQuantity
                })
                .then(response => {
                    this.zoneGroups = makeZoneGroups(response.tickets)

                    if (Object.values(this.zoneGroups)
                        .some(zoneGroup => {
                            return isTicketStrikethroughPricing(zoneGroup.lowestPricedTicket, this.$store.state.strikethroughMinPriceThreshold, this.$store.state.strikethoughDiscountThreshold)
                        })) {
                        this.$googleTagManager.pushEvent('Zone - Strikethrough Appears', {
                            eventCategory: 'UX - Zone Performer',
                            eventAction: 'Appears',
                            eventLabel: 'Zone - Strikethrough Appears'
                        })
                    }

                    this.selectedTicket = this.isPreviousSelectedZoneStillValid(this.selectedTicket)
                        ? this.zoneGroups[this.selectedTicket.seating.zone_group.id].lowestPricedTicket
                        : null
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    this.isFetchingTicketsWithMinimumQuantity = false
                })
        },
        isPreviousSelectedQuantityStillValid(selectedQuantity) {
            return Boolean(selectedQuantity) && this.availableQuantities.some(quantity => quantity === this.selectedQuantity)
        },
        isPreviousSelectedZoneStillValid(selectedTicket) {
            return Boolean(selectedTicket && this.zoneGroups[selectedTicket.seating.zone_group.id])
        },
        clearAllSections() {
            this.selectedEvent = null
            this.selectedQuantity = null
            this.selectedTicket = null
            this.zoneGroups = {}
            this.setStep(STEP.SELECT_AN_EVENT)
        },
        closeOpenStep() {
            this.openStep = null
        },
        isEventSelected(event) {
            return (this.selectedEvent || {}).id === event.id
        },
        isZoneGroupSelected(zoneGroupId) {
            return (((this.selectedTicket || {}).seating || {}).zone_group || {}).id === Number(zoneGroupId)
        },
        isValidStep(nextStep) {
            if (nextStep === STEP.SELECT_A_QUANTITY) {
                return Boolean(this.selectedEvent)
            }
            if (nextStep === STEP.SELECT_A_ZONE) {
                return Boolean(this.selectedEvent) && Boolean(this.selectedQuantity)
            }
            if (nextStep === STEP.FINALIZE_DETAILS) {
                return Boolean(this.selectEvent) && Boolean(this.selectedQuantity) && Boolean(this.selectedTicket)
            }
            return true
        },
        pluralizeQuantity(quantity) {
            return quantity > 1 ? `${quantity} Tickets` : `${quantity} Ticket`
        },
        purchase() {
            if (!this.selectedTicket.id) { return }
            if (!this.selectedQuantity) { return }

            this.$http
                .get(`/groupon-api/token/${this.selectedTicket.id}`,{
                    params: {
                        zone: 1,
                        quantity: this.selectedQuantity,
                    }
                })
                .then(response => {
                    this.$store.dispatch('postMessage', {
                        eventType: 'reservationPlaced',
                        data: {
                            prereservationId: response.body.token
                        }
                    })

                    if (!this.$googleTagManager) { return }
                    this.$googleTagManager.pushEvent('Zone - Buy Now Clicked', {
                        eventCategory: 'UX - Zone',
                        eventAction: 'Click',
                        eventLabel: 'Zone - Buy Now Clicked'
                    })

                    if (isTicketStrikethroughPricing(this.selectedTicket, this.$store.state.strikethroughMinPriceThreshold, this.$store.state.strikethoughDiscountThreshold)) {
                        this.$googleTagManager.pushEvent('Zone - Strikethrough Clicked', {
                            eventCategory: 'UX - Pre-checkout',
                            eventAction: 'Click',
                            eventLabel: 'Zone - Strikethrough Clicked'
                        })
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        },
        selectEvent(selectedEvent) {
            this.selectedEvent = selectedEvent
            this.setStep(STEP.SELECT_A_QUANTITY)
            this.fetchTicketsForSelectedEvent()
        },
        selectQuantity(quantity) {
            this.selectedQuantity = quantity
            this.setStep(STEP.SELECT_A_ZONE)
            this.fetchTicketsWithMinimumQuantity()

        },
        selectZoneGroup(zoneGroup) {
            this.selectedTicket = zoneGroup.lowestPricedTicket

            if (isTicketStrikethroughPricing(this.selectedTicket, this.$store.state.strikethroughMinPriceThreshold, this.$store.state.strikethoughDiscountThreshold)) {
                this.$googleTagManager.pushEvent('Strikethrough Clicked on Zone Event Listings', {
                    eventCategory: 'UX - Zone Performer',
                    eventAction: 'Click',
                    eventLabel: 'Strikethrough Clicked on Zone Event Listings'
                })
            }
            this.setStep(STEP.FINALIZE_DETAILS)
        },
        setStep(nextStep) {
            if (this.isValidStep(nextStep)) {
                this.openStep = nextStep
            }
        }
    },
    props: {
        performerId: {
            required: true,
            type: String,
            validator: value => !isNaN(value)
        }
    },
    watch: {
        performerId: {
            immediate: true,
            handler() {
                this.performer = null

                this.$marketplace
                    .getPerformer(this.performerId)
                    .then(performer => {
                        this.performer = performer
                        this.$emit('updateHead')
                        this.isFetchingEvents = true
                        this.$marketplace
                            .getEntityEvents('performers', this.performer.id, {
                                all_in_price: 1,
                                whereabouts: 'home'
                            })
                            .then(events => {
                                this.events = events.chronological.results

                                this.selectedEvent = this.$route.query.event_id ? this.events.find(event => event.id === Number(this.$route.query.event_id)) || null : null
                                if (this.selectedEvent) {
                                    this.setStep(STEP.SELECT_A_QUANTITY)
                                    this.fetchTicketsForSelectedEvent()
                                } else {
                                    this.setStep(STEP.SELECT_AN_EVENT)
                                }
                            })
                            .catch(err => {
                                console.error(err)
                            })
                            .finally(() => {
                                this.isFetchingEvents = false
                            })
                    })
                    .catch(err => {
                        this.$router.replace({ name: 'not-found' })
                    })
            }
        }
    }

}
</script>

<style lang="postcss" scoped>
@import 'theme';

.zone-group-header {
    min-height: 80px;
    padding-left: 10px;
    @media (--mq-large-min) {
        padding-left: 0;
    }

    & .zone-group-header__performer {
        font-size: 32px;
        font-weight: 700;
        line-height: 42px;
        margin: 0;
    }

    & .zone-group-header__venue-container {
        align-items: center;
        display: flex;
    }

    & .zone-group-header__venue {
        color: #727578;
        font-size: var(--fs-normal);
        font-weight: 400;
        line-height: 21px;
        margin: 6px 0 10px 5px;
    }
}

.zone-group {
    @media (--mq-large-min) {
        display: flex;
    }
}

.map-container {
    @media (--mq-large-min) {
        border-right: 1px solid #D5D8DB;
        padding-right: 20px;
        width: calc(100% - 360px );
    }
}

.map {
    @media (--mq-large-min) {
        align-items: center;
        border-radius: 5px;
        border: 1px solid #D5D8DB;
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

.panel-container {
    @media (--mq-large-min) {
        padding-left: 20px;
    }
}

.map-status {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
}

.panel {
    border: 1px solid #D5D8DB;
    @media (--mq-large-min) {
        width: 340px;
    }
}

.panel-step {
    border-bottom: 1px solid #D5D8DB;
    & .panel-step__header {
        display: flex;
        justify-content: space-between;

        & .section-header-container {
            align-items: center;
            display: flex;
            width: 100%;
        }

        /* Class need to override Groupon override stylesheet */
        & .section-header-text {
            color: #333333;
            font-size: var(--fs-normal);
            font-weight: 600;
            margin: 0;
            padding: 14px 14px 14px 10px;

            &.section-header-text--disabled {
                color: #fff;
                @media (--mq-large-min) {
                    color: #333333;
                }
            }
        }
    }

    & .panel-step__content {
        background-color: #F6F7F8;
        overflow: hidden;
    }

    & .panel-step__events, & .panel-step__zones {
        list-style-type: none;
        margin: 0;
        padding: 0;

        & li:last-child {
            border-bottom: none;
        }
    }

    & .panel-step__event {
        cursor: pointer;
        border-bottom: 1px solid #D5D8DB;
        padding: 20px 15px;

        &:hover {
            background-color: #E6E7E8;
        }

        &.panel-step__event--selected {
            background-color: #D4E8C5;
        }
    }

    & .panel-step__zone-grouping {
        align-items: center;
        border-bottom: 1px solid #D5D8DB;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        line-height: 21px;
        padding: 20px 15px;

        & .zone-grouping__current_price {
            color: #444649;

            &.zone-grouping__current_price--strikethrough {
                color: var(--color-prim);
                font-weight: 700;
                text-align: right;
            }
        }

        & .zone-grouping__prices {
            display: flex;
            flex-direction: column;
        }

        & .zone-grouping__original_price {
            color: #707174;
            text-decoration: line-through;
        }

        & .zone-grouping__strikethrough-message {
            color: var(--color-prim);
            font-size: var(--fs-small);
        }

        &:hover {
            background-color: #E6E7E8;
        }

        &.panel-step__zone-grouping--selected {
            background-color: #D4E8C5;
        }
    }

    &.panel-step--clickable {
        cursor: pointer;
    }

    &.panel-step--open {
        & .panel-step__header {
            border-bottom: 1px solid #E6E7E8;
        }
    }

    &.panel-step--disabled {
        background-color: #a5a8ab;
        @media (--mq-large-min) {
            background-color: transparent;
            cursor: not-allowed;
        }
    }
}

.clear-all-sections {
    background-color: #F6F7F8;
    border: none;
    color: var(--color-prim);
    font-size: var(--fs-normal);
    font-weight: 600;
    padding: 1.2em;
    text-align: center;
    width: 100%;

    &:hover {
        background-color: #53a318;
        color: #fff;
    }

    &.clear-all-sections--disabled {
        background-color: #a5a8ab;
        color: var(--color-prim);
        cursor: not-allowed;
        @media (--mq-large-min) {
            background-color: #F6F7F8;
        }
    }
}

.buy-button-container {
    padding: 10px;
    @media (--mq-large-min) {
        padding: 10px 0;
    }
}

.buy-button {
    background-color: var(--color-prim);
    border-radius: 5px;
    color: #fff;
    font-size: var(--fs-normal);
    font-weight: 700;
    height: 40px;
    padding: 0;
    width: 100%;

    &:hover {
        background-color: var(--color-prim-dk)
    }

    &.buy-button--disabled {
        background-color: #a5a8ab;
        cursor: not-allowed;
    }
}

>>> .fx-spinner {
    background-color: transparent;
    padding: 10px;
    position: relative;
}

.icon-calendar-blank,
.icon-stadium,
.icon-ticket-2 {
    padding-left: 10px;
}

.icon {
    &.icon--disabled {
        color: #fff;
        @media (--mq-large-min) {
            color: #333333;
        }
    }
}

.lowest-available-price {
    padding: 0 0 10px 10px;
    @media (--mq-large-min) {
        padding: 0 0 10px 0;
    }

    & .lowest-available-price__value {
        color: var(--color-prim);
        font-weight: 600;
    }

    & .lowest-available-price__from {
        color: #707174;
    }

    & .lowest-available-price__price {
        font-size: var(--fs-larger);
    }
}

.available-quantities {
    align-items: stretch;
    display: flex;
    height: 50px;
    justify-content: space-between;

    & .available-quantities__buttons {
        display: flex;
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    & .available-quantities__button-container {
        width: calc(100% / 7);
    }

    & .available-quantities__button {
        background-color: transparent;
        border: none;
        font-size: var(--fs-normal);
        height:100%;
        padding: 10px;
        width: 100%;

        &:hover {
            background-color: #E6E7E8;
        }

        &.available-quantities__button--hidden {
            visibility: hidden;
        }

        &.available-quantities__button--selected {
            background-color: #D4E8C5;
        }

    }

    & .available-quantities__less, & .available-quantities__more {
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: var(--fs-normal);
        padding: 10px;

        &.available-quantities__less--hidden, &.available-quantities__more--hidden {
            visibility: hidden;
        }
    }
}

.date {
    color: #75787B;
    font-size: var(--fs-small);
}

.collapse-arrow-down {
    background-color: transparent;
    border: none;
    cursor: inherit;
    height: 100%;
}

.collapse-arrow-up {
    background-color: transparent;
    border: none;
    cursor: inherit;
    cursor: pointer;
    height: 100%;
}

.icon-chevron-lg-down,
.icon-chevron-lg-up {
    color: #0076d6;
    font-size: 14px;
    font-weight: 600;
    &.icon-chevron-lg-down--disabled {
        color: #fff;
        @media (--mq-large-min) {
            color: #0076d6;
        }
    }
}

.strikethrough-pricing {
    display: flex;
    justify-content: space-between;
    & .strikethrough-pricing__prices {
        display: flex;
    }

    & .strikethrough-pricing__discount-message {
        display: flex;
        flex-direction: column;
        font-size: var(--fs-small);
        justify-content: flex-end;
        text-align: right;
    }

    & .strikethrough-pricing__original-price {
        color: #707174;
        font-size: var(--fs-larger);
        padding-right: 10px;
        text-decoration: line-through;
    }

    & .strikethrough-pricing__new-price {
        font-size: var(--fs-larger);
    }

    & .strikethrough-pricing__from {
        color: #707174;
        visibility: visible;
        &.strikethrough-pricing__from--hidden {
            visibility: hidden;
        }
    }
}

.accordion-enter-active, .accordion-leave-active {
    max-height: 230px;
    transition: 500ms ease-out;
}

.accordion-enter, .accordion-leave-to {
    max-height: 0px;
    opacity: 0;
}

ul.breakdown {
  list-style: none;
  padding: 0;
  margin-top:4px;
  & li {
  margin-bottom:0!important;
  &.total-row{
    margin-top:8px;
   }
  }
  & span{
    color:#7A7A7A;
    display:inline-block;
    padding:0;
    width:50%;
    &.text-left{
      text-align:left;
     }
    &.text-right{
       text-align:right;
     }
    &.total-price{
    color:#373737;
    font-size:19px;
    }
   &.free{
    color:#53A318
    }
  }
}
</style>
