<template>
    <div>
        <warning/>
        <div class="event-component">
            <header v-if="event">
                <div class="left">
                    <h1
                        class="primary-heading"
                        data-e2e-value="event-heading">{{ event.name }}</h1>
                    <h2 class="secondary-heading">
                        <span class="fx-hidden--small fx-hidden--medium">{{ $formatDate(event.local_date, 'dddd, MMMM D, YYYY') }}</span>
                        <span class="fx-hidden--large fx-hidden--full">{{ $formatDate(event.local_date, 'ddd, MMM D, YYYY') }}</span> &#183;
                        <span v-if="event.tbd">{{ $t('event.time-tbd') }}</span>
                        <span v-else>{{ $formatDate(event.local_date, 'h:mma') }}</span>&nbsp;&#183;
                        <span>{{ event.venue.name }}, {{ event.venue.city_name }} {{ event.venue.subdivision }}</span>
                    </h2>
                </div>
            </header>
            <div class="seat-select-wrapper" v-if="event">
                <fx-seat-select
                    :ticket="ticketId"
                    :enable-pinning="enablePinning"
                    :enable-quantity-filter-modal="isShowQuantityModal"
                    :event="event"
                    :filter-quantity="filterQuantity"
                    :include-fees="$store.state.isAllInPricing"
                    :is-mobile-filters-open="isMobileFiltersOpen"
                    :is-mobile="$store.state.isMobile"
                    :padding="$store.state.isMobile ? { top: 46, right: 12, bottom: 12, left: 12 } : 32"
                    :purchase-button-copy="event.venue.subdivision === 'NY' ? $t('event.ny-purchase-button') : $t('event.purchase-button')"
                    :quantity="selectedQuantity"
                    :ticket-list-header-copy="$t('event.ticket-list-heading')"
                    @close-mobile-filters="onCloseMobileFilters"
                    @close-quantity-filter-modal="onCloseQuantityFilterModal"
                    @open-mobile-filters="onOpenMobileFilters"
                    @purchase="onTicketPurchase"
                    @quantity-filters-selected="onQuantityFilterSelected"
                    @quantity-modal-selected="onQuantityModalSelected"
                    @quantity-selected="onQuantitySelected"
                    @ticket-deselected="onTicketDeselected"
                    @ticket-object-selected="onTicketObjectSelected"
                    @ticket-selected="onTicketSelected"
                    @tickets-loaded="onTicketsLoaded"
                >
                    <template slot="ticket-info" slot-scope="props">
                        <div
                            class="ticket-info-strikethrough-pricing__discount-message"
                            v-if="!ticketId && isTicketStrikethroughPricing(props.ticket, $store.state.strikethroughMinPriceThreshold, $store.state.strikethoughDiscountThreshold)">
                            <span>
                                <strong>{{ $formatNumeral(calculatePercentageDifference(props.ticket.all_in_price.price_per, props.ticket.price_trend.past_min_price), '0%') }} LESS</strong>
                                than {{ props.ticket.price_trend.span }} days ago
                            </span>
                        </div>
                    </template>
                    <template slot="ticket-price" slot-scope="props">
                        <div
                            class="strikethrough-pricing"
                            v-if="isTicketStrikethroughPricing(props.ticket, $store.state.strikethroughMinPriceThreshold, $store.state.strikethoughDiscountThreshold)"
                        >
                            <div class="strikethrough-pricing__original-price">
                                {{ $formatNumeral(props.ticket.price_trend.past_min_price, '$0,0') }}
                            </div>
                            <div class="strikethrough-pricing__new-price">
                                <span><strong>{{ $formatNumeral(props.ticket.all_in_price.price_per, '$0,0') }}</strong><span class="ticket-price-currency__strikethrough">{{ props.ticket.currency}}</span></span>
                                <span class="strikethrough-pricing__quantity-text">each</span>
                            </div>
                        </div>
                        <div
                            class="ticket-price"
                            v-else
                        >
                            <span v-if="ticketSelected && ticketId && props.ticket.price_breakdown">
                                <span class="ticket-price__price">
                                    {{ $formatNumeral(props.ticket.price_breakdown.total, '$0,0.00') }}<span
                                    class="ticket-price__currency">&nbsp;{{ props.ticket.currency }} ea</span>
                                </span>
                            </span>
                            <span v-else>
                                <span class="ticket-price__price">{{ $formatNumeral(props.ticket.all_in_price.price_per, '$0,0') }}<span class="ticket-price__currency">{{ props.ticket.currency}}</span></span>
                            </span>
                            <span v-if="ticketSelected && ticketId && props.ticket.price_breakdown" class="ticket-price__quantity-text">Includes {{ $formatNumeral(calculateFeeBreakdown(props.ticket, event.venue.subdivision), '$0,0.00') }} in fees</span>
                            <span v-else class="ticket-price__quantity-text">each</span>
                        </div>
                    </template>
                    <template
                        slot="precheckout-loyalty-info" slot-scope="props">
                        <section
                            class="strikethrough-info"
                            v-if="isTicketStrikethroughPricing(props.selectedTicket, $store.state.strikethroughMinPriceThreshold, $store.state.strikethoughDiscountThreshold)"
                        >
                            <span class="icon-alert strikethrough-info__icon"></span>
                            <span>
                                <strong>{{ $formatNumeral(calculatePercentageDifference(props.selectedTicket.all_in_price.price_per, props.selectedTicket.price_trend.past_min_price), '0%') }} LESS</strong>
                                than {{ props.selectedTicket.price_trend.span }} days ago
                            </span>
                        </section>
                    </template>
                    <template
                        slot="seat-view-ticket-price" slot-scope="props">
                            <div v-if="isTicketStrikethroughPricing(props.selectedTicket, $store.state.strikethroughMinPriceThreshold, $store.state.strikethoughDiscountThreshold)">
                                <div class="seat-view-strikethrough-pricing">
                                    <div class="seat-view-strikethrough-pricing__original-price">
                                        {{ $formatNumeral(props.selectedTicket.price_trend.past_min_price, '$0,0') }}
                                    </div>
                                    <div class="seat-view-strikethrough-pricing__new-price">
                                        <span>{{ $formatNumeral(props.selectedTicket.all_in_price.price_per, '$0,0') }}</span><span class="ticket-price-currency__strikethrough">{{ props.selectedTicket.currency}}</span>
                                    </div>
                                </div>
                                <span class="seat-view-strikethrough-pricing__quantity-text">each</span>
                            </div>
                            <div v-else>
                                <div v-if="ticketSelected && ticketId && props.selectedTicket.price_breakdown" class="primary listing-price-breakdown">
                                    {{ $formatNumeral(props.selectedTicket.price_breakdown.total, '$0,0.00') }}<span
                                    class="seat-view-ticket-price__currency">&nbsp;{{ props.selectedTicket.currency }} ea</span>
                                </div>
                                <div v-else class="primary">
                                    {{ $formatNumeral(props.selectedTicket.all_in_price.price_per, '$0,0') }}<span
                                    class="seat-view-ticket-price__currency">&nbsp;{{ props.selectedTicket.currency }}</span>
                                </div>
                                <div v-if="ticketSelected && ticketId && props.selectedTicket.price_breakdown" class="secondary each">Includes {{ $formatNumeral(calculateFeeBreakdown(props.selectedTicket, event.venue.subdivision), '$0,0.00') }} in fees</div>
                                <div v-else class="secondary each">each</div>
                            </div>
                    </template>
                    <template slot="precheckout-ticket-info" slot-scope="props" >
                        <ul class="precheckout-ticket-info">
                            <li v-if="props.selectedTicket.notes">
                                <TicketInfoSellerNotes :seller-notes="props.selectedTicket.notes"/>
                            </li>
                            <li v-if="props.selectedTicket.is_spec">
                                <TicketInfoZoneSeating :selected-ticket="props.selectedTicket"/>
                            </li>
                            <li>
                                <span class="icon-ticket-2"></span>
                                <span v-if="event.is_lmlp && props.selectedTicket.stock_type.key === 'hard'" v-html="$t('delivery-details.hard-lmlp')"></span>
                                <span v-else v-html="$t('delivery-details.' + props.selectedTicket.stock_type.key)"></span>
                            </li>
                            <li>
                                <TicketInfoGuarantee :guarantee="$t('event.vivid-seats-guarantee')"/>
                            </li>
                        </ul>
                    </template>
                </fx-seat-select>
            </div>
        </div>
    </div>
</template>

<script>
import { calculatePercentageDifference, getStringWithQuantity, isTicketStrikethroughPricing } from '../../services/StrikethroughService'
import { calculateFeeBreakdown } from '../../services/TicketService'
import DatePlugin from 'core/plugins/DatePlugin.js'
import env from 'env'
import SeatSelectComponent from 'core/components/SeatSelectComponent.vue'
import StorageUtil from 'core/utilities/StorageUtil.js'
import TicketInfoGuarantee from 'core/components/TicketInfoGuaranteeComponent.vue'
import TicketInfoSellerNotes from 'core/components/TicketInfoSellerNotesComponent.vue'
import TicketInfoZoneSeating from 'core/components/TicketInfoZoneSeatingComponent.vue'
import Utils from 'core/utilities/Utils.js'
import warning from './Warning.vue'

export default {
    computed: {
        isShowQuantityModal() {
            return this.$store.state.isMobile && !StorageUtil.session.get('has-dismissed-quantity-modal')
        }
    },
    head: {
        title() {
            let title = this.$t('event.title-event-default')
            if (this.event) {
                title = this.$t('event.title-with-event', {
                    title: this.event.name,
                    venue: this.event.venue.name,
                    date: DatePlugin.format(this.event.local_date, 'MMM D, YYYY')
                })
                this.$googleTagManager.pushPageView(this.$route.fullPath, title)
            }
            return {
                inner: title
            }
        }
    },
    props: ['eventId'],
    data() {
        return {
            enablePinning: false,
            event: null,
            filterQuantity: null,
            isMobileFiltersOpen: false,
            selectedQuantity: null,
            ticketId: null,
            ticketSelected: null
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(route) {
                this.ticketId = parseInt(route.query.ticket_id) || null
                this.selectedQuantity = parseInt(route.query.quantity) || null
                this.isMobileFiltersOpen = Boolean(Number(route.query.mobile_filters))
                this.filterQuantity = parseInt(route.query.filter_quantity) || 0
            }
        },
        selectedQuantity() {
            this.fetchSelectedTicketPricingThrottled()
        },
        selectedTicket() {
            this.fetchSelectedTicketPricingThrottled()
        }
    },
    created() {
        this.fetchSelectedTicketPricingThrottled = Utils.throttle(this.fetchSelectedTicketPricing, 50)

        this.$marketplace.getEvent(this.eventId)
            .then(event => {
                if (event.is_expired) {
                    this.$router.replace({ name: 'not-found' })
                }
                else {
                    this.event = event
                    this.enablePinning = (this.event.taxonomy || {}).segment === 'sports'
                    this.$emit('updateHead')

                    this.$googleTagManager.pushEvent('TrackEventDetails', {
                        eventCategory: 'Event Details',
                        eventAction: 'Pageview',
                        eventLabel: `Event - ${event.name}`
                    })
                    // Report application height after DOM is rerendered
                    let finalHeight
                    if (this.$store.state.isMobile) {
                        finalHeight = window.screen.availHeight + 37
                    } else {
                        finalHeight = document.getElementById('appContainer').offsetHeight + 37
                    }
                    this.$nextTick(() => {
                        this.$store.dispatch('postMessage', {
                            eventType: 'contentLoaded',
                            data: {
                                finalHeight
                            }
                        })
                    })
                }
            }, errors => {
                this.$router.replace({ name: 'not-found' })
            })
    },
    methods: {
        calculateFeeBreakdown,
        calculatePercentageDifference,
        getStringWithQuantity,
        isTicketStrikethroughPricing,
        fetchSelectedTicketPricing() {
            if (this.ticketId) {
                // Note: hard-coding 'US' b/c that is what the Get Tickets API assumes when estimating All-In-Price.
                this.$marketplace
                    .getTicketSummary(this.ticketId, {
                        all_in_price: 0,
                        quantity: this.selectedQuantity,
                        users_country: 'US'
                    })
                    .then(ticketSummary => {
                        this.$store.commit('setSelectedTicketPricing', ticketSummary.ticket.pricing)
                    })
                    .catch(err => {
                        console.error(err)
                        this.$store.commit('setSelectedTicketPricing', null)
                    })
            } else {
                this.$store.commit('setSelectedTicketPricing', null)
            }
        },
        onCloseMobileFilters() {
            this.$router.replace({
                query: this.updateQuery({
                    mobile_filters: 0
                })
            })
        },
        onCloseQuantityFilterModal() {
            StorageUtil.session.set('has-dismissed-quantity-modal', true)
            this.trackClick('Close Need Seat')
        },
        onOpenMobileFilters() {
            this.$router.push({
                query: this.updateQuery({
                    mobile_filters: 1
                })
            })
        },
        onQuantityFilterSelected(filterQuantity) {
            this.$router.replace({
                query: this.updateQuery({
                    filter_quantity: filterQuantity
                })
            })
        },
        onQuantityModalSelected(quantity) {
            this.trackClick(`Need ${quantity} Seat`)
        },
        onQuantitySelected(quantity) {
            this.$router.replace({
                query: this.updateQuery({
                    quantity: quantity
                })
            })
        },
        onTicketDeselected() {
            this.ticketSelected = false
            this.$router.replace({
                query: this.updateQuery({
                    ticket_id: null,
                    quantity: null
                })
            })
        },
        onTicketObjectSelected(selectedTicket) {
            if (isTicketStrikethroughPricing(selectedTicket, this.$store.state.strikethroughMinPriceThreshold, this.$store.state.strikethoughDiscountThreshold)) {
                this.$googleTagManager.pushEvent('Strikethrough Clicked on Non-Zone Event Listings', {
                    eventCategory: 'UX - Event Details',
                    eventAction: 'Click',
                    eventLabel: 'Strikethrough Clicked on Non-Zone Event Listings'
                })
            }
        },
        onTicketPurchase({ ticket }) {
            this.$http
                .get(`/groupon-api/token/${this.ticketId}`,{
                    params: {
                        zone: 0,
                        quantity: this.selectedQuantity,
                    }
                })
                .then(response => {
                    this.$store.dispatch('postMessage', {
                        eventType: 'reservationPlaced',
                        data: {
                            prereservationId: response.body.token
                        }
                    })

                    if (isTicketStrikethroughPricing(ticket, this.$store.state.strikethroughMinPriceThreshold, this.$store.state.strikethoughDiscountThreshold)) {
                        this.$googleTagManager.pushEvent('Non - Zone Strikethrough Clicked', {
                            'eventCategory': 'UX - Pre-checkout',
                            'eventAction': 'Click',
                            'eventLabel': `Non - Zone Strikethrough Clicked`
                        })
                    }

                    try {
                        const eventValue = ticket.all_in_price
                            ? Math.round(ticket.all_in_price.total)
                            : Math.round(ticket.price_per * this.selectedQuantity)

                        this.$googleTagManager.pushEvent('Non-Zone - Buy Now Clicked', {
                            'eventCategory': 'UX - Non-Zone',
                            'eventAction': 'Click',
                            'eventLabel': `Non-Zone - Buy Now Clicked`
                        })
                    } catch (err) {
                        console.error(err)
                    }
                }, error => {
                    this.$store.dispatch('postMessage', {
                        eventType: 'reservationFailed',
                        data: {
                            error
                        }
                    })
                })
        },
        onTicketSelected(ticketId) {
            this.$router.push({
                query: this.updateQuery({
                    ticket_id: ticketId,
                })
            })
            // Set a delay to prevent the listings page to show all in price for NY event after a listing has been selected
            this.$nextTick(function () {
                setTimeout(() => {
                    this.ticketSelected = true
                }, 405)
            })
        },
        onTicketsLoaded(tickets) {
            if (Object.values(tickets)
                .some(ticket => {
                    return isTicketStrikethroughPricing(ticket, this.$store.state.strikethroughMinPriceThreshold, this.$store.state.strikethoughDiscountThreshold)
                })) {
                this.$googleTagManager.pushEvent('Non - Zone - Strikethrough Appears', {
                    eventCategory: 'UX - Event Details',
                    eventAction: 'Appears',
                    eventLabel: 'Non - Zone - Strikethrough Appears'
                })
            }
        },
        trackClick(eventLabel) {
            if (!this.$googleTagManager) { return }
            this.$googleTagManager.pushEvent('event', {
                eventCategory: 'UX - Event',
                eventAction: 'Click',
                eventLabel
            })
        },
        updateQuery(queryParams) {
            let query = Object.assign({}, this.$route.query, queryParams)
            Object.keys(query).forEach(param => {
                if (!query[param]) {
                    delete query[param]
                }
            })
            return query
        }
    },
    components: {
        'fx-seat-select': SeatSelectComponent,
        warning,
        TicketInfoGuarantee,
        TicketInfoSellerNotes,
        TicketInfoZoneSeating
    }
}
</script>

<style lang="postcss">
@import "theme";

.fx-seat-select {
    font-family: 'Open Sans', sans-serif !important;
    @media (--mq-medium-max) {
        height: 100%;
        border: none !important;
    }
}

.fx-map-overlay {
    @media (--mq-medium-max) {
        top: 64px !important;
    }
}

</style>
<style lang="postcss" scoped>
@import "theme";

.each {
    color: #7A7A7A;
    font-weight: 300;
    font-size: 12px;
    text-align: right;
}

.event-component {
    @media (--mq-medium-max) {
        height: 100vh;
    }
    & > header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 24px;
        background-color: rgba(255, 255, 255, 0.85);
        @media (--mq-medium-max) {
            align-items: center;
            height: 64px;
            margin-bottom: 0;
            padding: 0 12px;
            position: relative;
            z-index: var(--layer-1);
            top: 0;
            left: 0;
            right: 0;
        }
        & > .left {
            flex-shrink: 1;
            flex-basis: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow-x: hidden;
            & > .primary-heading {
                margin: 0;
                font-size: var(--fs-largest);
                font-weight: var(--fw-medium);
                line-height: 27px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow-x: hidden;
                @media (--mq-medium-max) {
                    font-size: var(--fs-large);
                }
            }
            & > .secondary-heading {
                margin: 0;
                font-size: var(--fs-normal);
                font-weight: var(--fw-normal);
                line-height: 19px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow-x: hidden;
                @media (--mq-medium-max) {
                    font-size: var(--fs-small);
                }
            }
        }
    }
}

.seat-select-wrapper {
    height: calc(var(--d-column) * 2);
    @media (--mq-medium-max) {
        height: 100%;
    }
}

.sales-pitch {
    font-size: var(--fs-normal);
    & > li {
        display: flex;
        margin-bottom: 12px;
    }

    & .sales-pitch__icon {
        display: inline-flex;
        flex-shrink: 0;
        font-size: 18px;
        margin-right: 8px;

        &.sales-pitch__icon--icon-guarantee {
            margin-top: 2px
        }

        &.sales-pitch__icon--icon-ticket-2 {
            margin-top: 3px
        }
    }
}

.strikethrough-pricing {
    text-align: right;
    & .strikethrough-pricing__original-price {
        color: var(--c-gray-4);
        font-size: var(--fs-large);
        text-decoration: line-through;

    }

    & .strikethrough-pricing__new-price {
        color: #348700;
        display: flex;
        flex-direction: column;
        font-size: var(--fs-large);
        white-space: nowrap;
    }

    & .strikethrough-pricing__quantity-text {
        font-size: var(--fs-small);
        margin-top: -5px;
    }
}

/* override for the pinned tickets */
.fx-pinned-ticket {
    & .strikethrough-pricing {
        & .strikethrough-pricing__original-price {
            color: #fff;
            font-size: var(--fs-normal)
        }
        & .strikethrough-pricing__new-price {
            color: #fff;
            font-size: var(--fs-normal)
        }
        & .strikethrough-pricing__quantity-text {
            color: #fff;
            font-size: var(--fs-normal)
        }
    }
}

.ticket-info-strikethrough-pricing__discount-message {
    color: var(--color-prim);
}

.seat-view-strikethrough-pricing {
    color: #fff;
    text-align: right;
    & .seat-view-strikethrough-pricing__original-price {
        color: rgba(255,255,255,0.8);
        font-size: var(--fs-large);
        text-decoration: line-through;
    }
    & .seat-view-strikethrough-pricing__new-price {
        font-size: var(--fs-larger);
    }
    & .seat-view-strikethrough-pricing__quantity-text {
        font-size: var(--fs-small);
    }
}

.strikethrough-info {
    align-items: center;
    color: var(--color-prim);
    display: flex;
    & .strikethrough-info__icon {
        font-size: (--fs-larger);
        margin-right: 8px;
        margin-top: 5px;
    }
}

.ticket-price {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    & .ticket-price__price {
        font-size: var(--fs-larger);
        font-weight: var(--fw-bold);
        & .ticket-price__currency {
            color: #7A7A7A;
            font-weight: 200;
        }
    }

    & .ticket-price__quantity-text {
        color: #7A7A7A;
        font-size: var(--fs-small);
        margin-top: -5px;
        text-align: right;
    }
}

.icon-ticket-2 {
    font-size: 18px;
    margin-right: 8px;
}

>>> .fx-ticket-list > li {
    &:hover, &:focus, &.highlighted, &.active {
        border-left-color: var(--color-prim) !important;
    }
}

>>> .fx-switch > .switch.active > .rail {
    background-color: var(--color-prim) !important;
}

>>> .fx-select-dropdown > ul > li {
    &:hover {
        background-color: var(--color-prim-lt) !important;
    }
    &.selected {
        color: var(--color-prim) !important;
    }
}

>>> .fx-pinned-ticket {
    &:hover, &:active, &.highlighted, &.active {
        border: 3px solid var(--color-prim) !important;
    }
}

>>> input[type='text'] {
    height: 2.5rem !important;
    margin: 0px 8px 0px 8px !important;
    width: 58px !important;
    @media (--mq-medium-max) {
        width: 64px !important;
    }
}

>>> .kind--default {
    &.variant--accent {
        background-color: var(--color-prim) !important;
        &:disabled, &.loading {
            background: var(--color-prim) !important;
        }
        &:hover {
            box-shadow: none !important;
            background: none !important;
            background-color: var(--color-prim-dk) !important;
        }
        &:focus {
            box-shadow: none !important;
            background: none !important;
            background-color: var(--color-prim-dk) !important;
        }
        &:active, &.active {
            background: var(--color-prim-lt) !important;
        }
    }
}

>>> .kind--link {
    color: var(--color-prim) !important;
    &:hover, &:focus {
        border-color: var(--color-prim) !important;
    }
    &.active, &:active {
        color: var(--color-prim-lt) !important;
        border-color: var(--color-prim-lt) !important;
    }
}

>>> .kind--icon {
    &.variant--default, &.variant--square  {
        &:focus {
            border-color: var(--color-prim) !important;
        }
    }
    &.variant--knockout {
        & :active{
            color: var(--color-prim) !important;
        }
    }
}

>>> label {
    font-weight: initial
}

>>> .row {
    &::before, &::after {
        content: none !important;
    }
}

>>> .spinner > .path {
    stroke: var(--color-prim) !important;
}

>>> .fx-ticket-list-panel .inner .panel header h3 {
    font-weight: 600 !important;
    font-size: 16px !important;
}

>>> .vue-slider-process {
    background-color: var(--color-prim) !important;
}

>>>.ticket-price>.primary, >>>.ticket-row, >>>.ticket-section, >>>.primary-heading {
    font-weight: 600 !important;
}

>>> .fx-button.kind--default.variant--primary {
    background: var(--color-prim) !important;
}

>>> .kind--default.variant--default{
    border: 1px solid var(--color-prim) !important;
    color: var(--color-prim) !important;
    &:active {
        background: var(--color-prim-lt) !important;
    }
    &:hover {
        background: linear-gradient(180deg, white 0%, var(--color-prim-lt) 100%) !important;
    }
}

>>> .fx-ticket-quantity-filter-row {
    font-weight: 400 !important;
    }

>>> .image-slot {
    background-color: var(--color-prim) !important;
}

.fx-seat-select {

    & >>> .fx-ticket-details {
        height: calc(100% - 42px);

        @media (--mq-medium-max) {
            height: 100%;
        }
        & .fx-ticket-details__seating-info {
            & .primary {
                margin-top: 0;
                margin-bottom: 0;
            }
            & .secondary {
                & .price {
                    & .secondary {
                        float: right;
                        margin-top: -6px;
                    }
                    & .seat-view-strikethrough-pricing {
                        margin-top: -25px;
                        & .seat-view-strikethrough-pricing__original-price {
                            color: var(--c-gray-1);
                            font-size: 16px;
                            font-weight: 200;
                        }
                        & .seat-view-strikethrough-pricing__new-price {
                            color: #348700;
                            font-size: 16px;
                            & .ticket-price-currency__strikethrough {
                                font-weight: 200;
                            }
                        }
                    }
                    & .seat-view-strikethrough-pricing__quantity-text {
                        color: #348700;
                        float: right;
                        font-size: 12px;
                        font-weight: 200;
                        margin-top: -5px;
                    }
                }
            }
        }

        & .fx-ticket-details__no-seat-view {
            & .fx-ticket-header--stub {
                & .fx-ticket-stub {
                    & .right {
                        & .ticket-price {
                            display: flex;
                            flex-direction: column;
                            margin-right: 0;
                            & .strikethrough-pricing {
                                & .strikethrough-pricing__new-price {
                                    display: flex;
                                    flex-direction: column;
                                    & .strikethrough-pricing__quantity-text {
                                        margin-top: -5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    & >>> .no-seat-view {
        & .back {
            color: var(--c-primary);
            color: var(--color-prim) !important;

            &:active {
                color: var(--color-prim-lt) !important;
            }
        }
    }

    & >>> .quantity-modal {
        & .quantity-modal__heading {
            color: var(--color-prim) !important;
        }
    }
}

>>> .more-information-button {
    top: 50px !important;
}

>>> .fx-ticket-list {
    & .fx-ticket-stub {
        & .right {
            & .ticket-price {
                & .ticket-price__price {
                    font-weight: normal;
                    & .ticket-price__currency {
                        display: none;
                    }
                }
                & .strikethrough-pricing {
                    & .strikethrough-pricing__new-price {
                        & .ticket-price-currency__strikethrough {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

>>> .close-button-x {
    & .fx-button-icon {
        color: var(--color-prim);
    }
}

.listing-price-breakdown {
    text-align: right;
}

.seat-view-ticket-price__currency {
    color: #7a7a7a;
    font-weight: var(--fw-light);
}
</style>
