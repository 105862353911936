const isEventStrikethroughPricing = (event, minPrice, minThreshold) => {
    if (minPrice === null) { return false }
    if (minThreshold === null) { return false }
    if (!event.price_trend) { return false }
    if (event.price_trend.past_min_price < minPrice) { return false}
    if (event.venue.country_iso_code === 'CAD') { return false }
    if (event.price_trend.past_min_price === 0) { return false }
    if (calculatePercentageDifference(event.price.min, event.price_trend.past_min_price) >= minThreshold) { return true }
    return false
}

const isTicketStrikethroughPricing = (ticket, minPrice, minThreshold) => {
    if (minPrice === null) { return false }
    if (minThreshold === null) { return false }
    if (!ticket.price_trend) { return false }
    if (ticket.price_trend.past_min_price < minPrice) { return false}
    if (ticket.price_trend.past_min_price === 0) { return false }
    if (calculatePercentageDifference(ticket.all_in_price.price_per, ticket.price_trend.past_min_price) >= minThreshold) { return true }
    return false
}

const calculatePercentageDifference = (currentPrice, pastPrice) => {
    if (pastPrice === 0) { return NaN }
    return 1 - (currentPrice / pastPrice)
}

const getStringWithQuantity = (string, qty) => {
    if (qty > 1) {
        return string + " x " + qty
    }
    return string
}

export {
    calculatePercentageDifference,
    getStringWithQuantity,
    isEventStrikethroughPricing,
    isTicketStrikethroughPricing,
}
