<template>
    <div>
        <modal
            size="large"
            :open="isModalOpen"
            @close="closeModal"
        >
            <CovidInfo />
        </modal>
    </div>
</template>


<script>
import CovidInfo from './CovidImpactPage.vue'
import modal from './ModalComponent.vue'

export default {
    components: {
        CovidInfo,
        modal
    },
    data () {
        return {
            isModalOpen: false,
        }
    },
    methods: {
        closeModal () {
            this.isModalOpen = false
        },
        openModal () {
            this.isModalOpen = this.isModalOpen === false ? true : null
        }
    },
}
</script>

<style lang="postcss" scoped>
@import "theme";

>>> .warning-modal-component {
    align-items: flex-start !important;
    top: 24px !important;
    & .modal {
        opacity: 1;
    }
}

.warning-banner {
    background: #FCF1B6;
    color: #444649;
    padding: 8px;
    text-align: center;
    width: 100%;
    @media (--mq-medium-min) {
        margin-bottom: 18px;
    }
    & a {
        color: #444649;
        text-decoration: underline;
        cursor: pointer;
    }
}

</style>
