<template>
    <div class="page">
        <h1>Vivid Seats Terms</h1>
        <strong>Effective Date: August 5, 2020</strong>
        <hr>

        <p>Our goal is to make your search and purchasing experience as easy and efficient as possible. These Sales Terms and Conditions ("Sales Terms") govern your use of Vivid Seats.com, our mobile application, purchases through our customer call center and your purchase of any product from Vivid Seats, LLC (“Vivid Seats”). By using or visiting this site or purchasing tickets in any manner from the Vivid Seats Marketplace, you expressly agree to abide and be bound by these Sales Terms, as well as all applicable laws, ordinances and regulations. You represent that you are legally able to enter into a binding contract. If you are under the age of 18, then you may only use this site or our customer call center in conjunction with, and under the supervision of, a parent or guardian.</p>

        <p>Below you will find the full Sales Terms, which include but are not limited to, the specific items below that we are highlighting for you:</p>

        <ul>
            <li>All orders must be confirmed by the respective seller before the Buyer Guarantee takes effect.</li>
            <li>Ticket prices may be above "face value" listed on the ticket (they may also be below "face value").</li>
            <li>Confirmed orders may be filled with comparable or upgraded tickets if the original tickets are no longer available.</li>
            <li>If, for reasons of maintaining social distancing or other safety reasons related to the covid19 pandemic, venue security or other personnel relocate you to a location within the venue that is different from the seating location that you had purchased from us, or deny you access to an event for any reason, such relocation or denial of access will not qualify you for a refund or for other compensation.</li>
            <li>All sales are final; there are no cancellations, returns, or exchanges.</li>
            <li>If an event is canceled with no rescheduled date, you will receive a full refund of the purchase price (including taxes, service fees and delivery fees, less possible restocking fees), or a credit for use on a future purchase, as determined at our sole discretion (this may vary by jurisdiction ). If an event is postponed or rescheduled, and the original tickets are valid for entry at the time of the rescheduled event, your order will not qualify for a refund or other compensation.</li>
        </ul>

        <h2>The Marketplace</h2>
        <p>Vivid Seats acts as a ticket marketplace. This means that we are an intermediary between ticket buyers and ticket sellers, allowing ticket buyers to find tickets for all their favorite live events. It also means that the Vivid Seats Marketplace is not the ticket seller and the listed tickets are not listed by Vivid Seats on behalf of any venue, promoter, team, league or organizing group. Vivid Seats does not represent any official organizer of the events listed and is not suggesting any endorsement by or association with such official organizers or any venues, events, teams, leagues, performers or attractions. The Vivid Seats marketplace is open to the exchange of any tickets for which there is a market, and that do not violate Vivid Seats’ policies.</p>

        <h2>100% Buyer Guarantee</h2>
        <p>Designed to give you full peace-of-mind, safety, and security. You will receive full-service customer care. At Vivid Seats we believe that our customers deserve more than just the tickets you order. We know your ticket purchase is important, and we believe you deserve dedicated, helpful assistance from start-to-finish. That is why we are proud to be a Full-Service provider—with our very own industry-leading Customer Service Team, accessible by phone or online during extended business hours.</p>
        <p>Many of our competitors do not include service with their tickets—they simply take your order and forward it along to whoever is selling the tickets, passing the buck on any problems or concerns you may have. We are always here to help you with your order. You have our full dedication to provide you the industry's best service and protection so that you can focus on enjoying your event rather than shopping or worrying about your tickets.</p>

        <ul>
            <li>
                <h4>Your transaction will be safe and secure</h4>
                <p>We fully believe our customers' private and personal information should remain that way. We utilize recognized industry standards on privacy and security to ensue your transactions are safe and secure. In particular, unlike many of our competitors, we do NOT share your credit card information to whichever broker or other individual happens to be selling the tickets. Instead, we work on your behalf to procure your tickets, make payments to sellers, and handle any problems so you can have full peace of mind when placing your order. <a class="link" :href="`/privacy?brand=${$store.state.whitelabel}`" target="_blank">Click here</a> to learn more about our privacy and security partners and practices.</p>
            </li>
            <li>
                <h4>Your tickets will be delivered before the event</h4>
                <p>We recognize that many of our customers purchase tickets at the last minute, in other cities, or perhaps just as the event is announced. In all cases, delivering tickets to you requires skillful coordination and communication. To lay any such logistics concerns to rest, we guarantee that we will deliver your tickets to you in time for the event, or your money back. We always prefer to send tickets via secured courier, but when circumstances do not allow this, we will employ our Last Minute Local Delivery Services to handle any exceptions.</p>
            </li>
            <li>
                <h4>Your tickets will be valid and authentic</h4>
                <p>From our inception and as part of our mission, we have been dedicated to taking the fear out of buying tickets online. Vivid Seats has among the strongest relationships with licensed, certified, and professional ticket resellers in the industry. All sellers are rigorously evaluated according to their business practices and track record, and we guarantee your tickets to be valid and authentic, or your money back.</p>
            </li>
            <li>
                <h4>Your order will be accurate</h4>
                <p>We work in tight collaboration with our ticket resellers for your order, and we guarantee that the tickets you receive are identical, comparable or better than the ones you ordered, or your money back.</p>
            </li>
            <li>
                <h4>You will be compensated for a canceled event</h4>
                <p>If an event is canceled with no rescheduled date, you will receive  full compensation for the purchase price (including taxes, service fees, delivery fees, less possible restocking fees), or credit for use on a future purchase, as determined at our sole discretion. For events that are rescheduled, we will assist you with any ticket reissuing concerns or help you to sell your tickets if the new date is no longer desirable to you.</p>
            </li>
            <li>
                <h4>Vivid Seats has an outstanding track record of providing excellent customer service, and we are committed to achieving your satisfaction</h4>
                <p>In the rare event that there is a problem with your order as described above or otherwise, we pledge to work vigorously on your behalf to resolve it in time for your event, or if necessary, provide you with a full compensation as detailed in these Terms. It is our goal to provide you with the tickets you desire for your important events, and it is our pleasure to help you all along the way.</p>
            </li>
        </ul>

        <h2>Ticket Listings</h2>
        <p>Buyers may search for a specific artist or team, event date or other search category. Each listing contains the event name, date, seat location, and price per ticket. Please note, the ticket price may be higher than the “face value” that appears on the ticket. The “notes” section of the listing, if present, highlights special characteristics of the tickets contained in the listing. Because you will have an opportunity to look at the tickets available for a particular event and determine which tickets to purchase, it is your responsibility to confirm any relevant details by contacting Vivid Seats. Ticket Listings do not purport to state the type of ticket and delivery method is not indicative of whether or not a ticket is hard stock or a PDF or any other ticket format. Vivid Seats does not guarantee the accuracy of any information provided by sellers on the Vivid Seats Marketplace.</p>

        <h2>Selecting and Purchasing Tickets</h2>
        <p>Once you identify the tickets you would like to purchase, and select them, you will be directed through the checkout process where you will enter your name and address and provide payment information. You will also be able to review the ticket details, total order price, and service and delivery fees. The total order value will include a service and delivery fee that is added by Vivid Seats to the price of the tickets set by the seller. The service and delivery fees cover the costs of company operations, including connecting buyers to sellers with premium inventory to listed events, premium customer service, website improvements, and safe and secure checkout and delivery of ordered tickets.</p>
        <p>By placing an order, you authorize {{ partnerName }} to charge your method of payment for the total order amount. Your order is then sent to the seller for confirmation. The seller will confirm the tickets are still available and you will receive notification that the tickets will be delivered. If you do not receive notification of ticket delivery, contact us at <a class="link" :href="`mailto:${ partnerEmail }`">{{partnerEmail}}</a> for assistance.</p>

        <h2>Ticket Availability</h2>
        <p>All orders are subject to ticket availability. Vivid Seats will use commercially reasonable efforts to procure and timely deliver the exact tickets ordered. If those tickets are no longer available, Vivid Seats reserves the right to replace tickets with comparable or upgraded tickets, at Vivid Seats’ sole, reasonable discretion.</p>

        <h2>Zone Seating</h2>
        <p>For certain live events, we permit a limited number of pre-approved sellers to offer tickets for sale that they do not currently possess. These tickets are marked on the listing as "Zone Seating." If you purchase zone seating, the seller is committing to obtain the tickets described for you upon receipt of your order. These tickets, like all tickets sold on this site, are backed by our <a class="link" :href="`/guarantee?brand=${$store.state.whitelabel}`" target="_blank">100% Buyer Guarantee</a>. After you place your order and your order is confirmed, we guarantee that your tickets will be within the listed zone or section listed or one comparable and that you will receive these tickets in time for the event or your money back. Orders exceeding four tickets may be split up into different rows within the requested zone or section.</p>

        <h2>Payment</h2>
        <p>By placing your order, you authorize {{ partnerName }} to charge your method of payment for the total amount, which includes the ticket price and service and delivery fees. You agree that you will not attempt to evade, avoid, or circumvent any refund prohibitions in any manner, including by seeking a “chargeback,” with regard to tickets you purchased. In the event that you dispute a charge and it is determined that the charge was valid and not the result of credit card or other payment fraud, Vivid Seats has the right to (i) seek payment, including all associated fees, by whatever means necessary, including using collection agencies and legal proceedings; and (ii) refuse to honor pending and future ticket purchases made from all credit card accounts or online accounts on which such chargebacks have been made, and may prohibit future purchases from all persons in whose name the credit card accounts exist and any person who accesses any associated online account or credit card or who otherwise breaches this provision from using the Site. Vivid Seats may also mitigate its damages by relisting and selling the tickets that are the subject of the underlying payment dispute.</p>

        <h2>Pricing Errors</h2>
        <p>A large volume of tickets is listed on the Vivid Seats Marketplace and Vivid Seats makes every effort to prevent pricing errors. On rare occasions when pricing errors occur, Vivid Seats shall not be liable to the customer for this error. For the avoidance of doubt, if a ticket is subject to a pricing error, Vivid Seats may offer the tickets to you at the corrected price. If the corrected price is not acceptable to you, Vivid Seats will allow you to cancel your order.</p>

        <h2>Delivery of Tickets</h2>
        <p>Tickets may be delivered via Email, Courier or Special Delivery for the charge stated during the checkout process. Some tickets are delivered by an alternative method which will be described at the time of checkout or in a subsequent email. For listings with no designated delivery method, tickets will typically be shipped by courier. Fees consist of the actual cost of delivery as well as a surcharge that supports Vivid Seats’ safe and secure ticket delivery, whether electronic, via shipping, or otherwise. Delivery method is not indicative of the type of ticket. Tickets in all forms, including hard stock and PDF, may be shipped via courier. Special Delivery includes same day delivery and may include Will Call, onsite pick up at the venue, pick up from another designated location (typically within two (2) miles of the venue, if possible), or messenger service. The exact delivery location for Special Delivery orders will be designated by the seller.</p>
        <p>When tickets are shipped to you, it is your responsibility to confirm that the tickets delivered to you are consistent with the order you placed. You shall have forty-eight (48) hours from the time of delivery to report any inconsistencies between your order and the delivered tickets. Vivid Seats shall not be liable or responsible for any inconsistencies discovered after the 48-hour window has passed.</p>
        <p>Vivid Seats reserves the right to change the delivery method, at its sole discretion, and may deliver tickets for any order up to the event start time listed on the tickets. If the delivery method changes at any time prior to delivery, Vivid Seats will not charge the buyer additional delivery fees. You must notify Vivid Seats if you do not receive your tickets within 48 hours of the actual start time of the scheduled or rescheduled event. If you do not timely notify Vivid Seats, you may not be eligible for compensation based on non-delivery of tickets. Photo ID or other verification may be required to accept delivery.</p>

        <h2>Ticket Holder Behavior Policy</h2>
        <p>Attendees to live events must abide by the rules and policies of the venue, promoter and anyone else responsible for the event. Failure to follow such rules and policies may lead to consequences against you or the seller or original ticket holder. If you or another person using the ticket you purchased from Vivid Seats fails to abide by those rules and policies, you are responsible for all applicable fines and legal or other expenses associated therewith. If you are asked to leave the event or you miss part of the event due to suspected or confirmed inappropriate behavior, you will not qualify for a refund or any other compensation. Further, should any violation result in the loss of the ticket seller's season ticket rights or right to use any other tickets at that venue, or the right to purchase other tickets from that venue, you shall be held liable for all reasonable costs, expenses and losses associated with said loss including, but not limited to, all direct, indirect, vicarious, consequential, exemplary, incidental, special or punitive damages, including lost profits.</p>

        <h2>All Sales are Final</h2>
        <p><strong>All sales are final.</strong>No compensation will be given for any reason other than cancellation of the event or failure to timely deliver valid tickets. This policy is necessary because of our status as a live marketplace. When a purchase is confirmed, the seller removes the associated tickets from the seller’s inventory. The pricing in the market also changes frequently and just as the seller is not permitted to decline to confirm an order in anticipation of the market going up, buyers are not permitted to return tickets or cancel an order due to the market price going down. A buyer may sell their tickets on the Vivid Seats Marketplace if, after purchase, the buyer decides not to attend the event subject to certain restrictions, in which case a buyer may sell the tickets by alternative means.</p>
        <p>If Vivid Seats fails to deliver any ticket contracted for on time for the event at the time that it actually occurs, then Vivid Seats' sole obligation or liability shall be limited to compensating the buyer for any payments made to Vivid Seats under this Agreement. Vivid Seats reserves the right to cancel and refund the buyer's order at any time for any reason.</p>

        <h2>Cancelled and Postponed Events</h2>
        <p>For canceled events, Vivid Seats will refund the purchase price (including taxes and fees, less possible restocking fees), or will issue a credit for use on a future purchase, as determined in its sole discretion (this may vary by jurisdiction). To qualify for compensation, the buyer must return their tickets to Vivid Seats within 2 weeks of notice from Vivid Seats that the event is deemed “Cancelled.” No refunds or other compensation will be given without the original tickets, unless otherwise determined by Vivid Seats, in its sole discretion. Vivid Seats will determine when an event is canceled based upon the best information available. Postponed or rescheduled events will not be refunded or otherwise compensated.</p>
        <p>Event date, times, venue and subject matter may change. We are not always notified if a show is postponed, rescheduled or canceled. It is the buyer's responsibility to monitor the event and to confirm any changes to the event with the entity putting on the event. In certain instances, a venue, promoter, or any entity putting on the event will require a ticket holder to relocate his or her seat or otherwise change the seating configuration in a manner beyond Vivid Seat's control. Vivid Seats shall not be held responsible for any such change and will not be obligated to provide a refund or any other compensation.</p>
        <p>If, for reasons of maintaining social distancing or other safety reasons related to the covid19 pandemic, the entity putting on the event has announced a seating or attendee capacity reduction of greater than 15%, Vivid Seats, at its sole option, reserves the right to cancel tickets for seating locations eliminated by the venue or to deem such event cancelled in its entirety. In the event that Vivid Seats deems an event cancelled in its entirety based on a reduction in capacity: (i) all outstanding orders for such event will be cancelled; and (ii) the event will be reposted for sale with the adjusted capacity and known restrictions. Any buyers whose tickets have been cancelled pursuant to this paragraph shall receive compensation for such cancellation as described above.</p>

        <h2>Denial of Admission</h2>
        <p>If you have difficulty gaining admission to an event, contact us immediately by calling 1 (800) 969-8556. If the issue you are experiencing is not resolved and you are denied admission, you must obtain proof from the venue verifying that you were denied entry and send that to Vivid Seats along with a statement describing the specific circumstances. Vivid Seats will provide you with a form to complete.</p>
        <p>Upon receipt of the evidence and form, Vivid Seats will conduct an investigation. During that investigation, we will review the proof you submit and contact the seller. Other than any denial of entry caused by health or capacity concerns relating to the covid19 pandemic, in the event that Vivid Seats determines that you were indeed denied entry, at no fault of your own, you will receive a full refund of the cost of the ticket including all fees and shipping charges, as the sole remedy. If the investigation results in a finding that you either did not attempt to use your tickets, you were granted entry, or your claim that you were denied entry has been found to be false, you will not be refunded and you may be subject to the consequences described below.</p>
        <p>When attending an event, you may be required by the venue or event organizer or such party’s agent to sign a waiver or other legal document in order to gain access to the venue.  By purchasing tickets from us, you understand that you may be denied entry if you do not sign such waiver and such denial of entry will not be the responsibility of Vivid Seats.</p>
        <p>Due to the uncertainty related to the COVID-19 pandemic, your tickets and admission to the event are subject to all venue and/or event organizer/promoter safety and health policies. You acknowledge that due to the evolving nature of the pandemic, the venue and/or event organizer/promoter may continue to develop and update these policies in the intervening time between your purchase and the event date. By using tickets, you acknowledge and agree that you will comply with such policies and your attendance at the event is conditioned on such compliance. If your admission to the event is denied or revoked because you have willfully failed or refused to comply with any such safety and health policies of the venue and/or event organizer/promoter, you will not be eligible for any compensation from Vivid Seats.</p>

        <h2>Lost, Stolen, or Damaged Tickets</h2>
        <p>Please keep your tickets in a safe place. Vivid Seats is not responsible for lost, stolen, damaged or destroyed tickets and will not provide compensation for your order if you cannot locate your tickets once they are delivered to you. As stated above, Vivid Seats is a Marketplace and not associated with any venue so we do not generate tickets and cannot reissue replacements. Please note that direct sunlight or heat can damage certain types of tickets.</p>
        <p>We may, at your request, ask the seller to re-issue your tickets. If you would like us to submit this request, the payment method on file will be charged 15% of the order total for the re-issuance of your tickets (maximum of $200). If a re-issue is not possible, you will be refunded the re-issue fee.</p>

        <h2>Privacy Policy</h2>
        <p>Vivid Seats uses your information only as described in Vivid Seats' Privacy Policy. <a class="link" :href="`/privacy?brand=${$store.state.whitelabel}`" target="_blank">View Privacy Policy</a></p>

        <h2>Authorization</h2>
        <p>You authorize your wireless operator to disclose to Vivid Seats and its third party service providers your mobile number, name, address, email, network status, customer type, customer role, billing type, mobile device identifiers (IMSI and IMEI) and other subscriber status and device details, if available, solely to verify your identity and prevent fraud for the duration of the business relationship. See our <a class="link" :href="`/privacy?brand=${$store.state.whitelabel}`" target="_blank">Privacy Policy</a> for how we treat your data.</p>

        <h2>Unlawful Activity; Acceptable Use</h2>
        <p>Vivid Seats prohibits the use of its Marketplace, website, or mobile application for unlawful conduct. All users must comply with all local, state, federal and international laws, ordinances and regulations. By using this site, you agree not to use any false personal information or use an invalid or unauthorized credit or debit card. You agree not to use or permit anyone to use information provided through Vividseats.com for any unlawful or unauthorized purpose.</p>
        <p>We are the sole interpreter of this site's intended and acceptable use. This site is intended to be used by individuals or companies seeking to buy or sell tickets for ultimate use by an individual to attend an event and for no other purpose. If you are a venue, promoter or anyone else responsible for controlling admission to an event, you may not access our site or purchase tickets on this site for purposes of (i) identifying tickets available on our site or the sellers of those tickets; or (ii) revoking or voiding any tickets offered by sellers on this site or otherwise penalizing any sellers for offering tickets on this site.</p>

        <h2>Investigations and Consequences</h2>
        <p>Vivid Seats monitors compliance with these Sales Terms and investigates alleged violations and other complaints against users. This includes any attempt to defraud, or otherwise harm Vivid Seats or sellers on the Marketplace. You are required to cooperate in such investigations including, but not limited to, providing any and all information requested.</p>
        <p>Following an investigation, or for any other reason, Vivid Seats may, without prior notice, enforce certain consequences for violations of the Sales Terms, attempts to harm Vivid Seats and/or sellers on the Marketplace, or failure to cooperate with an investigation. Such consequences include but are not limited to:</p>

        <ul>
            <li>Canceling confirmed orders</li>
            <li>Issuing a warning</li>
            <li>Suspending or terminating your account</li>
            <li>Denying access to the Vivid Seats Marketplace</li>
            <li>Rejecting pending orders</li>
            <li>Blocking future transactions</li>
            <li>Taking legal action such as filing civil or criminal charges, or seeking an injunction.</li>
        </ul>

        <p>You agree that monetary damages may not provide a sufficient remedy to Vivid Seats for violations of these Sales Terms and that the damages and harm caused by such violation may lead to impaired good will, lost sales and increased expenses that are difficult to calculate. Accordingly, you consent to injunctive or other equitable relief for such violations. Vivid Seats reserves the right to report to appropriate law enforcement authorities or other relevant third parties any activity that it believes, in its sole discretion, may in any way violate any local, state, federal or international law.</p>

        <h2>Intellectual Property Ownership</h2>
        <p>Website frames hosted by Vivid Seats, including all software, databases, trademarks, logos, service marks, proprietary information and materials (and any intellectual property and other rights relating thereto) ("Vivid Seats' Property") are owned by Vivid Seats and will remain the property of Vivid Seats. Users of these website frames acknowledge that they do not acquire any ownership rights by using the site. Users may not use any Vivid Seats' Property in connection with any product or service that is not offered by Vivid Seats, in any manner that is likely to cause confusion with Vivid Seats' business, or in any manner that disparages Vivid Seats. Nothing contained on Vivid Seats Property should be construed as granting, by implication, estoppel or otherwise, any license or right to use any Vivid Seats' Property without the express written permission of Vivid Seats.</p>
        <p>The content, organization, graphics, design, compilation, "look and feel" and all Vivid Seats Property available on this site, including, without limitation, images and written and other materials (the "Contents"), are intellectual property protected under the copyright, trademark and other intellectual property laws of the United States and/or other countries ("Intellectual Property Laws"). You may not download, print, copy, reproduce, distribute, transmit, broadcast, display, sell, license or otherwise use or exploit any of the Content except in the course of viewing the site online for lawful purposes, and in making single copies of selected pages of the site for personal use and not for distribution or posting on any other site. You also agree not to modify, rent, lease, loan, sell, distribute or create derivative works based on any Content. The violation of applicable Intellectual Property Laws may give rise to civil and/or criminal penalties. No right, title or interest in any downloaded materials is transferred to you as a result of any such downloading or copying other than the foregoing license to possess for personal use.</p>
        <p>Vivid Seats respects the intellectual property of others, and we ask you to do the same. If you believe that your work has been copied in a way that constitutes copyright infringement, please provide our Copyright Agent with the following information: (a) an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest; (b) a description of the copyrighted work that you claim has been infringed; (c) a description of where the material that you claim is infringing is located on the site; (d) your address, telephone number and email address; (e) a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and (f) a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf. Our Copyright Agent for notice of claims of copyright infringement can be reached at: Attn: Copyright Complaints 24 E. Washington St., Floor 9, Chicago, IL 60602 or <a class="link" href="mailto:legal@vividseats.com">legal@vividseats.com</a>.</p>

        <h2>Indemnification</h2>
        <p>You agree to indemnify, defend and hold Vivid Seats, its parents, affiliates, licensors, suppliers, advertisers and sponsors, and their respective employees, consultants, agents and other representatives ("Indemnified Parties") harmless from and against any and all claims, damages, losses, costs (including reasonable attorneys' fees) and other expenses that arise directly or indirectly out of or from: (a) your breach of any of these Sales Terms; (b) any allegation that any information you submit or transmit to the site infringe or otherwise violates the copyright, trademark, trade secret or other intellectual property or other rights of any third party; (c) any federal, state, or county tax obligation or amounts due or owing under any tax regulation, law, order or decree or any dispute concerning the tax status of Vivid Seats; and/or (d) your activities in connection with your use of this site.</p>

        <h2>Disclaimers and Limitations on Liability</h2>
        <h2>No Warranty</h2>
        <p>THE SITE, THE MATERIALS ON THE SITE AND ANY TICKET OR SERVICE OBTAINED THROUGH THE SITE IS PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. VIVID SEATS DISCLAIMS, TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, ALL WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO THE SITE, THE MATERIALS, AND ANY TICKETS OR SERVICE OBTAINED THROUGH THE SITE, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT OF THIRD PARTY RIGHTS, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES THAT MAY ARISE FROM COURSE OF DEALING OR USAGE OF TRADE. VIVID SEATS DOES NOT WARRANT THAT YOUR USE OF THE SITE WILL BE UNINTERRUPTED, ERROR-FREE OR SECURE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU ASSUME ALL RESPONSIBILITY AND RISK FOR YOUR USE OF THE SITE AND YOUR RELIANCE THEREON. VIVID SEATS IS NOT RESPONSIBLE IN ANY WAY FOR THE ACCURACY OR SUITABILITY OF ANY PAYMENT OF TAXES TO ANY ENTITY ON YOUR BEHALF. YOUR USE OF THE SITE AND ANY MATERIALS PROVIDED THROUGH THE SITE ARE ENTIRELY AT YOUR OWN RISK. YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</p>

        <h2>Limitation of Liability</h2>
        <p>NEITHER VIVID SEATS NOR ANY OTHER INDEMNIFIED PARTY ARE OR WILL BE RESPONSIBLE OR LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE OR OTHER DAMAGES (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS OR LOST PROFITS), UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY ARISING OUT OF OR RELATING IN ANY WAY TO THE SITE, THE MATERIALS ON THE SITE OR ANY TICKET OR SERVICE OBTAINED THROUGH THE SITE (INCLUDING ATTENDANCE AT ANY EVENT). WITHOUT LIMITING THE FOREGOING, YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT VIVID SEATS AND ANY OTHER INDEMNIFIED PARTY SHALL HAVE NO LIABILITY OR RESPONSIBILITY WHATSOEVER FOR: (I) ANY ACTION OF ANOTHER USER TO THE SITE; (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, WHETHER ARISING IN CONTRACT OR IN TORT, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, OR ATTENDANCE AT AN EVENT, INCLUDING ANY CLAIM, CAUSE OF ACTION, OBLIGATION, LIABILITY, RIGHT, OR REMEDY WHETHER OR NOT ARISING FROM THE NEGLIGENCE OF VIVID SEATS; (III) ANY UNAUTHORIZED ACCESS; (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE; (V) ANY BUGS, VIRUSES, WORMS, DEFECTS OR OTHER ITEMS OF A DESTRUCTIVE NATURE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY; (VI) ANY ERROR, MISTAKE, INACCURACY OR OMISSION IN ANY MATERIALS, OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY MATERIALS AVAILABLE THROUGH THE SITE; AND/OR (VII) ANY LOST, STOLEN OR DAMAGED TICKETS. YOUR SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH THE SITE IS TO STOP USING THE SITE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.</p>
        <p>THE MAXIMUM LIABILITY OF VIVID SEATS, AND ANY OTHER INDEMNIFIED PARTY, AND YOUR SOLE AND EXCLUSIVE REMEDY, FOR ALL DAMAGES, LOSSES SUFFERED BY YOU AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE) OR OTHERWISE, SHALL BE THE GREATER OF (A) THE TOTAL AMOUNT PAID BY YOU, IF ANY, TO ACCESS THE SITE OVER THE PAST TWELVE (12) MONTHS; OR (B) $200. </p>
        <p>YOU ACKNOWLEDGE AND AGREE THAT NEITHER VIVID SEATS NOR ANY OF THE INDEMNIFIED PARTIES ARE LIABLE, AND YOU AGREE NOT TO SEEK TO HOLD VIVID SEATS AND THE INDEMNIFIED PARTIES LIABLE, FOR THE CONDUCT OF THIRD PARTIES, INCLUDING OPERATORS OF VENUES OR EVENT ORGNIZERS/PROMOTERS, AND THAT THE RISK OF INJURY FROM SUCH THIRD PARTIES, SITES AND LOCATIONS RESTS ENTIRELY WITH YOU.</p>

        <h2>Arbitration and Dispute Resolution</h2>
        <p>You and Vivid Seats each agree that any and all disputes, controversies, or claims arising out of or relating to: (i) these Sales Terms; (ii) your use of, or access to, this site; (iii) Vivid Seats' services; or (iv) any tickets or other items sold or purchased through this site shall be resolved exclusively through final and binding arbitration rather than in court. The Federal Arbitration Act governs the interpretation and enforcement of this agreement to arbitrate.</p>
        <p>The arbitrator, and not any federal, state, or local court or agency, shall have exclusive authority to resolve any dispute arising out of or relating to the interpretation, applicability, enforceability or formation of this agreement to arbitrate, any part of it, or of these Sales Terms including, but not limited to, any claim that all or any part of this agreement to arbitrate or the Sales Terms is void or voidable.</p>
        <p>If either you or Vivid Seats intends to bring a claim against the other, that party must first send to the other, by certified mail, a completed Intent to Arbitrate Notice ("Intent Notice"). The Intent Notice to Vivid Seats should be sent to Vivid Seats, LLC, Attention: Litigation Department, 24 E. Washington St., Floor 9, Chicago, IL 60602. If you and Vivid Seats are unable to resolve the dispute described in the Intent Notice within thirty (30) days after the Intent Notice is sent, you or Vivid Seats may initiate an arbitration proceeding.</p>
        <p>The arbitration will be conducted by the American Arbitration Association ("AAA") in accordance with its Commercial Arbitration Rules, as modified by this agreement to arbitrate. Exclusive jurisdiction for any such arbitration shall be in Chicago, Illinois. The AAA's rules are available at www.adr.org. A form for initiating arbitration proceedings, Demand for Arbitration, is available on the AAA's website. In addition to filing the Demand for Arbitration with the AAA, in accordance with its rules and procedures, you must send a copy of the completed form to Vivid Seats at the following address: Vivid Seats LLC, Attention: Litigation Department, 24 E. Washington St., Floor 9, Chicago, IL 60602.</p>
        <p>Payment of all filing, administration and arbitrator fees will be governed by the AAA's rules, unless otherwise stated in this agreement to arbitrate. If the value of the relief sought is $10,000 or less, at your request, Vivid Seats will pay all filing, administrative and arbitrator fees associated with the arbitration. Any request for payment of fees by Vivid Seats should be submitted by mail to the AAA along with the Demand for Arbitration and Vivid Seats will make arrangements to pay all necessary fees directly to the AAA. In the event the arbitrator determines the claim you asserted in the arbitration to be frivolous or brought for an improper purpose, you agree to reimburse Vivid Seats for all fees associated with the arbitration paid by Vivid Seats.</p>
        <p>The arbitrator will decide the substance of all claims in accordance with the laws of the State of Illinois. The arbitrator's award shall be final and binding, and judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction thereof.</p>
        <p>You can choose to reject this agreement to arbitrate. If you do not wish to be bound by this agreement to arbitrate, you must notify Vivid Seats in writing within thirty (30) days of the date that you first access the site. Your written notification must include your name and address, as well as a clear statement that you do not wish to resolve disputes with Vivid Seats through arbitration. Written notification should be mailed by certified mail to: Vivid Seats, LLC, Attention: Litigation Department, 24 E. Washington St., Floor 9, Chicago, IL 60602.</p>
        <p>YOU AND VIVID SEATS AGREE THAT EACH MAY ONLY BRING CLAIMS AGAINST THE OTHER ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING UNLESS BOTH YOU AND VIVID SEATS ACKNOWLEDGE AND AGREE THAT THE WAIVER IS MATERIAL AND ESSENTIAL TO THE ARBITRATION OF ANY DISPUTES BETWEEN THE PARTIES AND IS NON-SEVERABLE FROM THE AGREEMENT TO ARBITRATE CLAIMS. IF THE WAIVER IS LIMITED, VOIDED OR FOUND UNENFORCEABLE, THEN THE PARTIES' AGREEMENT TO ARBITRATE SHALL BE NULL AND VOID WITH RESPECT TO SUCH PROCEEDING, SUBJECT TO THE RIGHT TO APPEAL THE LIMITATION OR INVALIDATION OF THE WAIVER. UNLESS YOU AND VIVID SEATS AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON'S OR PARTY'S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY'S INDIVIDUAL CLAIMS. ANY RELIEF AWARDED CANNOT AFFECT OTHER VIVID SEATS' USERS.</p>
        <p>Pursuant to 815 ILCS 414/1.5(c), for transactions involving tickets to events in Illinois, You or ticket sellers may elect to submit complaints against one another to the American Arbitration Association ('AAA') under its rules and procedures. The AAA's rules are available at www.adr.org. Such complaints shall be decided by an independent arbitrator in accordance with these Sales Terms and other Terms and Conditions of using this Marketplace. You and ticket sellers also agree to submit to the jurisdiction of the State of Illinois for complaints involving a ticketed event held in Illinois.</p>

        <h2>Modification</h2>
        <p>Vivid Seats has the right, in its sole discretion, to modify, suspend or discontinue any part of this site at any time, with or without notice.</p>

        <h2>Changes in Terms and Conditions</h2>
        <p>Vivid Seats reserves the right, in its sole discretion, to change these Sales Terms at any time. If Vivid Seats changes any term or condition, said modification, revision and additional information shall be posted here and shall automatically replace the terms and conditions and become binding on all users of this site. Your continued use of the site following Vivid Seats' posting of revised terms and conditions constitute your acceptance of the revised agreement.</p>

        <h2>Force Majeure</h2>
        <p>Vivid Seats shall not be deemed in default or otherwise liable under these Sales Terms due to its inability to perform its obligations by reason of any act of God, fire, earthquake, blizzard, flood, epidemic, pandemic, accident, explosion, casualty, strike, lockout, labor controversy, riot, civil disturbance, act of public enemy, embargo, war, any law ordinance or regulation, legal order (unless caused by Vivid Seats' default hereunder), any failure or delay of any transportation, power, or communications system or any other similar cause not under Vivid Seats' control.</p>

        <h2>Allocation of Risk</h2>
        <p>You acknowledge and agree that the foregoing disclaimers and limitations of liability represent bargained for allocations of risk and that the pricing and other terms and conditions of this agreement reflect such allocation of risk.</p>

        <h2>Additional Provisions</h2>
        <p>No agency, partnership, joint venture or other relationship is intended or created by your use of the site.</p>
        <p>These Sales Terms, the use of the vividseats.com and our mobile application, and any purchases of tickets hereunder will be governed by the laws of the State of Illinois, without reference to conflict of law principles.</p>
        <p>These Sale Terms contain the entire understanding of the parties with respect to the matters contained herein and supersedes and replaces in its entirety any and all prior communications and contemporaneous agreements and understandings between the parties, whether oral, written, electronic or implied.</p>
        <p>If any provision of these Sales Terms is held invalid or unenforceable under any circumstance, its application in any other circumstances and the remaining provisions shall not be affected. Further, the provision that has been deemed to be invalid or ineffective shall be enforced to the greatest extent permitted by law.</p>
        <p>The heading at the beginning of each paragraph is for reference purposes and no way defines the scope or extent of such paragraph.</p>
    </div>
</template>

<script>
export default {
    computed: {
        partnerName () {
            return this.$store.state.whitelabel === 'livingsocial' ? 'Living Social': 'Groupon'
        },
        partnerEmail () {
            const serviceName = this.$store.state.whitelabel === 'livingsocial' ? 'LivingSocial': 'groupon';
            return `${serviceName}@vividseats.com`
        }
    }
}
</script>

<style lang="postcss" scoped>
    .page {
        padding: 0 10px 0 10px;
    }

    .link {
        color: var(--color-prim);
    }
</style>
