const calculateFeeBreakdown = (ticket, region) => {
    let fee = ticket.price_breakdown.service_fee
    if (['TN','CT'].includes(region)) {
        fee += ticket.price_breakdown.delivery_fee
    }
    return fee
}

export {
    calculateFeeBreakdown
}
